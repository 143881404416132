/**
 * This file holds various enumerated types for the tos tables.
 */
export const CoordinationState = {
    DEFAULT: {
      name: "DEFAULT",
      display: "Default",
      sortOrder: 3,
    },
    SAVED: {
        name: "SAVED",
        display: "Saved",
        sortOrder: 4,
    },
    REROUTE_REQUESTED: {
      name: "REROUTE_REQUESTED",
      display: "Reroute Requested",
      sortOrder: 5,
    },
    ATC_APPROVED: {
        name: "ATC_APPROVED",
        display: "ATC Approved",
        sortOrder: 6,
    },
    REROUTE_FILED: {
        name: "REROUTE_FILED",
        display: "Reroute Filed",
        sortOrder: 7,
    },
    ATC_UNABLE: {
        name: "ATC_UNABLE",
        display: "ATC Unable",
        sortOrder: 2,
    },
    ATC_EXCLUDED: {
        name: "ATC_EXCLUDED",
        display: "ATC Excluded",
        sortOrder: 1,
    },
    OPERATOR_EXCLUDED: {
        name: "OPERATOR_EXCLUDED",
        display: "FO Excluded",
        sortOrder: 0,
    },
    PENDING_ACTION: {
        name: "PENDING_ACTION",
        display: "Pending",
        sortOrder: 8,
    },
    PENDING_EXCLUDE: {
        name: "PENDING_EXCLUDE",
        display: "Pending Exclude",
        sortOrder: 9,
    },
};

export const EligibilityState = {
    POTENTIAL: {
        name: "POTENTIAL",
        display: "Potential",
        sortOrder: 3,
    },
    CANDIDATE: {
        name: "CANDIDATE",
        display: "Candidate",
        sortOrder: 4,
    },
    EXCLUDED: {
        name: "EXCLUDED",
        display: "Excluded",
        sortOrder: 1,
    },
    EXPIRED: {
        name: "EXPIRED",
        display: "Expired",
        sortOrder: 5,
    },
    PENDING_EXCLUDE: {
        name: "PENDING_EXCLUDE",
        display: "Pending Exclude",
        sortOrder: 9,
    },
    NOT_APPLICABLE: {
        name: "NOT_APPLICABLE",
        display: "Not Applicable",
        sortOrder: 10,
    },
};

export const FlightStatus = {
    SCHEDULED: {
        name: "SCHEDULED",
        display: "Scheduled",
        sortOrder: 1,
    },
    PUSHBACK: {
        name: "PUSHBACK",
        display: "Pushback",
        sortOrder: 2,
    },
    TAXI_OUT: {
        name: "TAXI_OUT",
        display: "Taxiing AMA",
        sortOrder: 3,
    },
};

export const TosSwimStatus = {
    NOT_INCLUDED: {
        name: "NOT_INCLUDED",
        display: "Not Included",
        sortOrder: 1,
    },
    SAVED: {
        name: "SAVED",
        display: "Saved",
        sortOrder: 2,
    },
    PENDING_SAVE: {
        name: "PENDING_SAVE",
        display: "Pending Save",
        sortOrder: 3,
    },
    PENDING_CANCEL: {
        name: "PENDING_CANCEL",
        display: "Pending Cancel",
        sortOrder: 4,
    },
    IN_SWIM: {
        name: "IN_SWIM",
        display: "In SWIM",
        sortOrder: 5,
    },
    FORMERLY_INCLUDED: {
        name: "FORMERLY_INCLUDED",
        display: "Formerly Included",
        sortOrder: 6,
    }
};

export const FoAction = {
    SAVE_SWIM: {
        name: "SAVE_SWIM_TOS_ROUTE",
    },
    CANCEL_SWIM: {
        name: "CANCEL_SWIM_TOS_ROUTE",
    },
    REQUEST_TOS_ROUTE: {
        name: "REQUEST_TOS_ROUTE",
    },
    CANCEL_REQUEST_TOS_ROUTE: {
        name: "CANCEL_REQUEST_TOS_ROUTE",
    },
};
