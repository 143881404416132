import * as actions from "../constants/ActionTypes";
import { TIME_CURRENT } from "../constants/Constants";
import { tosClient } from "../utils/tosClient";

/**
 * This file contains the redux actions used to send information to the
 * redux store.
 */

/**
 * Used to add a new table
 *
 * @param {string} tableId  ID of table being added
 */
export const addTable = tableId => ({
    type: actions.ADD_TABLE,
    payload: { tableId },
});

/**
 * Used to move the specified table up in the display order.
 *
 * @param {string} tableId  ID of table being moved
 */
export const moveTableUp = tableId => ({
    type: actions.MOVE_TABLE_UP,
    payload: { tableId },
});

/**
 * Used to move the specified table down in the display order.
 *
 * @param {string} tableId  ID of table being moved
 */
export const moveTableDown = tableId => ({
    type: actions.MOVE_TABLE_DOWN,
    payload: { tableId },
});

/**
 * Used to remove the specified table
 *
 * @param {string} tableId  ID of table being removed
 */
export const removeTable = tableId => ({
    type: actions.REMOVE_TABLE,
    payload: { tableId },
});

/**
 * Used to indicate table data refresh has started
 */
export const startTableUpdates = () => ({
    type: actions.START_UPDATE,
});

/**
 * Used to indicate that a particular table has finished updating
 */
export const notifyTableUpdated = tableId => ({
    type: actions.TABLE_UPDATED,
    payload: { tableId },
});

/**
 * Used to update the table columns for the specified table
 *
 * @param {string}   tableId  the table id for these columns
 * @param {string[]} data     the updated columns array
 */
export const updateColumns = (tableId, data) => ({
    type: actions.UPDATE_COLUMNS,
    payload: { tableId, data },
});

/**
 * Used to update the defined sorting for a table.
 *
 * @param {string}     tableId  the table id for sorting
 * @param {string[][]} data     the sorting 2D array
 */
export const updateOrdering = (tableId, data) => ({
    type: actions.UPDATE_ORDER,
    payload: { tableId, data },
});

/**
 * Used to update saved filter for a table.
 *
 * @param {string} tableId  the table id for filter
 * @param {object} data     the searchBuilder definition
 */
export const updateFilter = (tableId, data) => ({
    type: actions.UPDATE_FILTER,
    payload: { tableId, data },
});

/**
 * Used to update the number of rows to show on a page for a table.
 *
 * @param {string} tableId  the table id for filter
 * @param {number} data     the number of rows to show
 */
export const updateTableLength = (tableId, data) => ({
    type: actions.UPDATE_TABLE_LENGTH,
    payload: { tableId, data },
});

/**
 * Used to reset settings when loading new settings.
 */
export const resetSettings = () => ({
    type: actions.RESET_SETTINGS,
    payload: {},
});

/**
 * Used to signal when the settings have finished resetting after a load.
 */
export const resetSettingsComplete = () => ({
    type: actions.RESET_SETTINGS_COMPLETE,
    payload: {},
});

/**
 * Used to update the tos status
 *
 * @param {object} data  The updated tos status data
 */
export const updateTosStatus = (data) => ({
    type: actions.UPDATE_TOS_STATUS,
    payload: {data}
});

/**
 * Used to update the departures data
 *
 * @param {object[]} data  The updated departures data
 */
export const updateDepartures = (data) => ({
    type: actions.UPDATE_DEPARTURES,
    payload: {data}
});

/**
 * Used to update the flight menu data
 *
 * @param {object[]} data  The updated flight menu data
 */
export const updateFlightRoutes = (data) => ({
    type: actions.UPDATE_FLIGHT_ROUTES,
    payload: {data}
});

/**
 * Used to add new flight menu data
 *
 * @param {string} gufi         Flight identifier for new records
 * @param {object} flightData   Current departure record data for flight
 * @param {object[]} routeData  The new flight menu data
 */
export const addFlightRoutes = (gufi, flightData, routeData) => ({
    type: actions.ADD_FLIGHT_ROUTES,
    payload: {gufi, flightData, routeData}
});

/**
 * Used to add a gufi to a list of flight menus to display.
 *
 * @param {string}  tableId  The associated departure table id
 * @param {string}  gufi     The gufi to add
 */
export const addFlightMenuGufi = (tableId, gufi) => ({
    type: actions.ADD_FLIGHT_MENU_GUFI,
    payload: { tableId, gufi },
});

/**
 * Used to remove a gufi from a list of flight menus to display.
 *
 * @param {string}  tableId  The associated departure table id
 * @param {string}  gufi     The gufi to remove
 */
export const removeFlightMenuGufi = (tableId, gufi) => ({
    type: actions.REMOVE_FLIGHT_MENU_GUFI,
    payload: { tableId, gufi },
});

/**
 * Used to update the flights to include in alert.
 *
 * @param {string[]}  alertFlights  The new flight alert list
 */
export const updateAlertFlights = (alertFlights) => ({
    type: actions.UPDATE_ALERT_FLIGHTS,
    payload: { alertFlights },
});

/**
 * Used to update the time mode that determines what data is displayed.
 *
 * @param {string}  timeMode  The new time mode name
 */
export const updateTimeMode = (timeMode) => ({
    type: actions.UPDATE_TIME_MODE,
    payload: { timeMode },
});

/**
 * Used to update the timestamp associated with the data in the table.
 *
 * @param {object}  dataTimestamp  Luxon DateTime for requested data, or last data update
 */
export const updateDataTimestamp = (dataTimestamp) => ({
    type: actions.UPDATE_DATA_TIMESTAMP,
    payload: { dataTimestamp },
});

/**
 * Used to display a message to the user in the message modal dialog.
 *
 * @param {string} message  Message to display
 */
export const addMessage = (message) => ({
    type: actions.ADD_MESSAGE,
    payload: { message },
});

/**
 * Used to close the scratchpad editing modal.
 */
export const closeScratchModal = () => ({
    type: actions.CLOSE_SCRATCH_MODAL,
    payload: {},
});

/**
 * Used to open the scratchpad editing modal for a flight.
 *
 * @param {string} gufi      Flight gufi
 * @param {string} acid      Flight callsign
 * @param {array}  entries   Scratch pad entries
 */
export const openScratchModal = (gufi, acid, entries) => ({
    type: actions.OPEN_SCRATCH_MODAL,
    payload: { gufi, acid, entries },
});

/**
 * Used to close the alert type modal.
 */
export const closeAlertTypesModal = () => ({
    type: actions.CLOSE_ALERT_TYPES_MODAL,
    payload: {},
});

/**
 * Used to open the alert type modal.
 */
export const openAlertTypesModal = () => ({
    type: actions.OPEN_ALERT_TYPES_MODAL,
    payload: {},
});

/**
 * Used to close the color alert modal.
 */
export const closeColorAlertsModal = () => ({
    type: actions.CLOSE_COLOR_ALERTS_MODAL,
    payload: {},
});

/**
 * Used to open the color alert modal.
 */
export const openColorAlertsModal = () => ({
    type: actions.OPEN_COLOR_ALERTS_MODAL,
    payload: {},
});

/**
 * Used to close the error modal.
 */
export const closeErrorModal = () => ({
    type: actions.CLOSE_ERROR_MODAL,
    payload: {},
});

/**
 * Used to open the error modal.
 *
 * @param {string} title    the title to give the modal
 * @param {string} errMsg   the error message to display (up to 2 lines)
 */
export const openErrorModal = (title, errMsg) => ({
    type: actions.OPEN_ERROR_MODAL,
    payload: { title, errMsg },
});

/**
 * Used to close the hotline modal.
 */
export const closeHotlineModal = () => ({
    type: actions.CLOSE_HOTLINE_MODAL,
    payload: {},
});

/**
 * Used to open the hotline modal.
 */
export const openHotlineModal = () => ({
    type: actions.OPEN_HOTLINE_MODAL,
    payload: {},
});

/**
 * Used to update the alert type flags.
 *
 * @param {Object} alertTypes  flags for which visual alerts to show or
 *                             audible alerts to sound
 */
export const updateAlertTypes = (alertTypes) => ({
    type: actions.UPDATE_ALERT_TYPES,
    payload: { alertTypes },
});

/**
 * Used to update the color alerts.
 *
 * @param {Object} colorAlerts  specification for color alerts
 */
export const updateColorAlerts = (colorAlerts) => ({
    type: actions.UPDATE_COLOR_ALERTS,
    payload: { colorAlerts },
});

/**
 * Used to update the run time options for historical display.
 *
 * @param {string[]} message  Timestamps of TOS run times in the recent past
 */
export const updateRunTimes = (runTimes) => ({
    type: actions.UPDATE_RUN_TIMES,
    payload: { runTimes },
});

/**
 * Used to add flights to the Show Me table.
 *
 * @param {string[]} gufis Array of gufis to show in the table
 */
export const addShowMeFlights = (gufis) => ({
    type: actions.ADD_SHOW_ME_FLIGHTS,
    payload: { gufis },
});

/**
 * Used to add flights to the Show Me table which were specified in the Client .
 *
 * @param {string[]} gufis  Array of gufis to show in the table
 */
export const addShowMeClientFlights = (gufis) => ({
    type: actions.ADD_SHOW_ME_CLIENT_FLIGHTS,
    payload: { gufis },
});

/**
 * Used to remove a flight from the Show Me table.
 *
 * @param {string} gufi flight identifier to remove from table
 */
export const removeShowMeFlight = (gufi) => ({
    type: actions.REMOVE_SHOW_ME_FLIGHT,
    payload: { gufi },
});

/**
 * Used to clear all flights from the Show Me table
 */
export const clearShowMeFlights = () => ({
    type: actions.CLEAR_SHOW_ME_FLIGHTS,
    payload: { },
});

/**
 * Used to update global search value.
 *
 * @param {string} newValue new global search value
 */
export const updateGlobalSearch = (newValue) => ({
    type: actions.UPDATE_GLOBAL_SEARCH,
    payload: { newValue },
});

/**
 * Used to indicate if a search was made and returned no values.
 *
 * @param {boolean} emptySearch true if search failed to find a flight
 */
export const setEmptySearch = (emptySearch) => ({
    type: actions.SET_EMPTY_SEARCH,
    payload: { emptySearch },
});

/**
 * Used to indicate if the table manager needs to update the refresh interval.
 *
 * @param {boolean} updateNeeded true if update to refresh interval is needed
 */
export const updateRefreshInterval = (intervalUpdateNeeded) => ({
    type: actions.UPDATE_REFRESH_INTERVAL,
    payload: { intervalUpdateNeeded },
});

/**
 * Creates a "thunk" for calling the server to get flight menu information
 * and current departure record information for a gufi and update storage
 * with the result. A thunk is basically a function that can contain multiple
 * calls to dispatch with logic between them. This is used like a standard
 * Redux action creator, and Redux will call the function when it would
 * normally pass a regular action object to the reducer.
 *
 * @param {String} role      role name
 * @param {String} carrier   carrier name
 * @param {String} gufi      flight identifier
 *
 * @return function to dispatch update requests, then dispatch success or
 *         failure.
 */
export function addRouteInformation(role, carrier, gufi)
{
    return (dispatch, getState) => {
        let depOptions = {
            role,
            carrier,
            gufi,
        };
        let routeOptions = {
            role,
            carrier,
            gufiList: [ gufi ],
        };

        const timeMode = getState().dataReducer.timeMode;
        if (timeMode !== TIME_CURRENT)
        {
            dispatch(addMessage("Loading route data for " + gufi));
            let timestamp = getState().dataReducer.dataTimestamp.toMillis();
            if (timestamp)
            {
                depOptions.timestamp = timestamp;
                routeOptions.timestamp = timestamp;
            }
        }

        Promise.all([ tosClient.getDepartureGufi(depOptions),
                      tosClient.getFlightRoutes(routeOptions) ])
        .then(([flightData, routeData]) => {
            dispatch(addFlightRoutes(gufi, flightData, routeData));
            return true;
        })
        .catch(error => {
            console.error(`Error retrieving route data for ${gufi}: ${error}`);
            return false;
        });
    }
}
