import { COLUMNS } from '../constants/ColumnField'

// Default table columns
const DEFAULT_DATA_TABLE_COLUMNS = [
    COLUMNS.TOS_DT_SCRATCH_PAD_COLUMN,
    COLUMNS.FLIGHT_ID_COLUMN,
    COLUMNS.TOS_DT_CPDLC_COLUMN,
    COLUMNS.RUNWAY_COLUMN,
    COLUMNS.DESTINATION_COLUMN,
    COLUMNS.ROUTE_COLUMN,
    COLUMNS.DEPARTURE_GATE_COLUMN,
    COLUMNS.EOBT_COLUMN,
    COLUMNS.TOS_DT_ESTIMATED_TAKE_OFF_TIME_COLUMN,
    COLUMNS.TOS_DT_TOP_ESTIMATED_TAKE_OFF_TIME_COLUMN,
    COLUMNS.TOS_DT_TOP_OFF_DELAY_SAVINGS_COLUMN,
    COLUMNS.TOS_DT_TOP_ROUTE_ID_COLUMN,
    COLUMNS.TOS_DT_TOP_RELATIVE_TRAJECTORY_COST_COLUMN,
    COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN,
    COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN,
    COLUMNS.TOS_DT_IN_DELAY_COLUMN,
    COLUMNS.TOS_DT_TOP_IN_DELAY_COLUMN,
    COLUMNS.FLIGHT_STATUS_COLUMN,
    COLUMNS.TOS_DT_TMI_INFO_COLUMN,
    COLUMNS.TOS_DT_ELIGIBILITY_STATE_COLUMN,
    COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN,
];

/**
 * Gets the default data table columns for the current user; any disabled
 * fields are filtered out of the list.
 *
 * @return default data table columns for the current user
 */
export function getUserDefaultDataTableColumns() {
    // Remove the fields for which the disabled flag is set
    let userFields = DEFAULT_DATA_TABLE_COLUMNS.filter((field) => {
        return !(field["disabled"] && (field["disabled"] === true));
    });

    // Pull just the column name
    let userCols = userFields.map(field => field.value);

    return userCols;
}

const DEFAULT_ROUTE_MENU_COLUMNS = [
    COLUMNS.TOS_FM_ROUTE_COLUMN,
    COLUMNS.TOS_FM_ROUTE_ID_COLUMN,
    COLUMNS.TOS_FM_ALT_FOR_RTC_COLUMN,
    COLUMNS.TOS_FM_DEPARTURE_GATE_COLUMN,
    COLUMNS.TOS_FM_RUNWAY_COLUMN,
    COLUMNS.TOS_FM_DISTANCE_NM_COLUMN,
    COLUMNS.TOS_FM_ADDITIONAL_NM_COLUMN,
    COLUMNS.TOS_FM_RTC_NUM_COLUMN,
    COLUMNS.TOS_FM_RELATIVE_TRAJECTORY_COST_COLUMN,
    COLUMNS.TOS_FM_ESTIMATED_TAKE_OFF_TIME_COLUMN,
    COLUMNS.TOS_FM_OFF_DELAY_SAVINGS_COLUMN,
    COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_CARRIER_COLUMN,
    COLUMNS.TOS_FM_ELIGIBILITY_STATE_COLUMN,
    COLUMNS.TOS_FM_COORDINATION_STATE_COLUMN,
    COLUMNS.TOS_FM_IN_SWIM_COLUMN
];

// first hidden columns in the table
export const FIXED_ROUTE_COLUMNS = ["TOS_FM_CURRENT_ROUTE_COLUMN", "TOS_FM_TOP_ROUTE_COLUMN"];
export const DEFAULT_ROUTE_SORT = [["TOS_FM_TOP_ROUTE_COLUMN", "desc"], ["TOS_FM_OFF_DELAY_SAVINGS_COLUMN", "asc"], ["TOS_FM_ADDITIONAL_NM_COLUMN", "asc"]];

/**
 * Gets the default route menu columns for the current user; any disabled
 * fields are filtered out of the list.
 *
 * @return default route menu columns for the current user
 */
export function getUserDefaultRouteMenuColumns() {
    // Remove the fields for which the disabled flag is set
    let userFields = DEFAULT_ROUTE_MENU_COLUMNS.filter((field) => {
        return !(field["disabled"] && (field["disabled"] === true));
    });

    // Pull just the column name
    let userCols = userFields.map(field => field.value);

    return userCols;
}

/**
 * The ordering and grouping of columns as necessary for selection.
 * Note: The react-dual-listbox requires each field have a value and label
 * property.
 */
export const ALL_DATA_TABLE_COLUMNS =  [
    {
        label: 'TOS',
        options: [
            COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN,
            COLUMNS.TOS_DT_CPDLC_COLUMN,
            COLUMNS.TOS_DT_ELIGIBILITY_STATE_COLUMN,
            COLUMNS.TOS_DT_ESTIMATED_TAKE_OFF_TIME_COLUMN,
            COLUMNS.TOS_DT_NUM_TOS_CANDIDATE_COLUMN,
            COLUMNS.TOS_DT_NUM_TOS_SUBMITTED_COLUMN,
            COLUMNS.TOS_DT_SCRATCH_PAD_COLUMN,
            COLUMNS.TOS_DT_SWIM_STATUS_COLUMN,
            COLUMNS.TOS_DT_TIME_TO_EXPIRATION_COLUMN,
            COLUMNS.TOS_DT_TMI_INFO_COLUMN,
            COLUMNS.TOS_DT_TOP_ADDITIONAL_NM_COLUMN,
            COLUMNS.TOS_DT_TOP_ROUTE_ID_COLUMN,
            COLUMNS.TOS_DT_TOP_ROUTE_COLUMN,
            COLUMNS.TOS_DT_TOP_DEPARTURE_FIX_COLUMN,
            COLUMNS.TOS_DT_TOP_DEPARTURE_GATE_COLUMN,
            COLUMNS.TOS_DT_TOP_ESTIMATED_TAKE_OFF_TIME_COLUMN,
            COLUMNS.TOS_DT_TOP_ROUTE_TYPE_COLUMN,
            COLUMNS.TOS_DT_TOP_RELATIVE_TRAJECTORY_COST_COLUMN,
            COLUMNS.TOS_DT_TOP_RTC_NUM_COLUMN,
            COLUMNS.TOS_DT_TOP_RUNWAY_COLUMN,
        ],
    },
    {
        label: 'TOS: Delay',
        options: [
            COLUMNS.TOS_DT_IN_DELAY_COLUMN,
            COLUMNS.TOS_DT_OFF_DELAY_COLUMN,
            COLUMNS.TOS_DT_TOP_IN_DELAY_COLUMN,
            COLUMNS.TOS_DT_TOP_OFF_DELAY_COLUMN,
        ],
    },
    {
        label: 'TOS: Delay Savings',
        options: [
            COLUMNS.TOS_DT_TOP_IN_DELAY_SAVINGS_COLUMN,
            COLUMNS.TOS_DT_TOP_OFF_DELAY_SAVINGS_COLUMN,
            COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN,
            COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN,
        ],
    },
    {
        label: 'TOS: Aggregate Delay Savings',
        options: [
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN,
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN,
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN,
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_AIRPORT_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_TRACON_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_FLEET_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER1_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER2_COLUMN,
        ],
    },
    {
        label: 'General',
        options: [
            COLUMNS.AIRCRAFT_TYPE_COLUMN,
            COLUMNS.AIRLINE_COLUMN,
            COLUMNS.AMA_TAXI_DURATION_COLUMN,
            COLUMNS.DEPARTURE_FIX_COLUMN,
            COLUMNS.DEPARTURE_GATE_COLUMN,
            COLUMNS.DESTINATION_COLUMN,
            COLUMNS.EDCT_COLUMN,
            COLUMNS.EOBT_COLUMN,
            COLUMNS.EQUIPMENT_COLUMN,
            COLUMNS.ALT_COLUMN,
            COLUMNS.FLIGHT_ID_COLUMN,
            COLUMNS.FLIGHT_STATUS_COLUMN,
            COLUMNS.GROUND_STOP_COLUMN,
            COLUMNS.INTERNATIONAL_COLUMN,
            COLUMNS.LOBT_COLUMN,
            COLUMNS.LONG_ON_BOARD_COLUMN,
            COLUMNS.MAJOR_CARRIER_COLUMN,
            COLUMNS.MIT_COLUMN,
            COLUMNS.ORIGIN_COLUMN,
            COLUMNS.PARKING_GATE_COLUMN,
            COLUMNS.POBT_COLUMN,
            COLUMNS.ROUTE_COLUMN,
            COLUMNS.RUNWAY_COLUMN,
            COLUMNS.RUNWAY_TIME_COLUMN,
            COLUMNS.SOBT_COLUMN,
            COLUMNS.TAXI_TIME_COLUMN,
        ],
    },
];

/**
 * The ordering and grouping of columns in flight menu as necessary for
 * selection.  Note: The react-dual-listbox requires each field have a value
 * and label property.
 */
export const ALL_FLIGHT_MENU_COLUMNS = [
    {
        label: 'TOS',
        options: [
            COLUMNS.TOS_FM_ADDITIONAL_NM_COLUMN,
            COLUMNS.TOS_FM_ALT_FOR_RTC_COLUMN,
            COLUMNS.TOS_FM_COORDINATION_STATE_COLUMN,
            COLUMNS.TOS_FM_DEPARTURE_GATE_COLUMN,
            COLUMNS.TOS_FM_DISTANCE_NM_COLUMN,
            COLUMNS.TOS_FM_ELIGIBILITY_STATE_COLUMN,
            COLUMNS.TOS_FM_ESTIMATED_TAKE_OFF_TIME_COLUMN,
            COLUMNS.TOS_FM_IN_SWIM_COLUMN,
            COLUMNS.TOS_FM_RELATIVE_TRAJECTORY_COST_COLUMN,
            COLUMNS.TOS_FM_RTC_NUM_COLUMN,
            COLUMNS.TOS_FM_ROUTE_COLUMN,
            COLUMNS.TOS_FM_ROUTE_TYPE_COLUMN,
            COLUMNS.TOS_FM_ROUTE_ID_COLUMN,
            COLUMNS.TOS_FM_RUNWAY_COLUMN,
            COLUMNS.TOS_FM_SWIM_STATUS_COLUMN,
            COLUMNS.TOS_FM_VALID_ROUTE_COLUMN
        ],
    },
    {
        label: 'TOS: Delay',
        options: [
            COLUMNS.TOS_FM_IN_DELAY_COLUMN,
            COLUMNS.TOS_FM_OFF_DELAY_COLUMN,
        ],
    },
    {
        label: 'TOS: Delay Savings',
        options: [
            COLUMNS.TOS_FM_IN_DELAY_SAVINGS_COLUMN,
            COLUMNS.TOS_FM_OFF_DELAY_SAVINGS_COLUMN,
            COLUMNS.TOS_FM_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN,
            COLUMNS.TOS_FM_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN,
        ],
    },
    {
        label: 'TOS: Aggregate Delay Savings',
        options: [
            COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN,
            COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN,
            COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
            COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_CARRIER_COLUMN,
        ],
     },
];

export const DATA_TABLE_FILTER_COLUMNS = [
    COLUMNS.AIRLINE_COLUMN,
    COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN,
    COLUMNS.DEPARTURE_GATE_COLUMN,
    COLUMNS.DESTINATION_COLUMN,
    COLUMNS.TOS_DT_ELIGIBILITY_STATE_COLUMN,
    COLUMNS.EOBT_COLUMN,
    COLUMNS.EQUIPMENT_COLUMN,
    COLUMNS.TOS_DT_ESTIMATED_TAKE_OFF_TIME_COLUMN,
    COLUMNS.ALT_COLUMN,
    COLUMNS.FLIGHT_STATUS_COLUMN,
    COLUMNS.HAS_APREQ_COLUMN,
    COLUMNS.HAS_EDCT_COLUMN,
    COLUMNS.HAS_SCRATCH_PAD_COLUMN,
    COLUMNS.TOS_DT_IN_DELAY_COLUMN,
    COLUMNS.INTERNATIONAL_COLUMN,
    COLUMNS.MAJOR_CARRIER_COLUMN,
    COLUMNS.TOS_DT_OFF_DELAY_COLUMN,
    COLUMNS.POBT_COLUMN,
    COLUMNS.RUNWAY_COLUMN,
    COLUMNS.TOS_DT_TIME_TO_EXPIRATION_COLUMN,
    COLUMNS.TOS_DT_TOP_ADDITIONAL_NM_COLUMN,
    COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN,
    COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN,
    COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN,
    COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
    COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN,
    COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_AIRPORT_COLUMN,
    COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER1_COLUMN,
    COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER2_COLUMN,
    COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_FLEET_COLUMN,
    COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_TRACON_COLUMN,
    COLUMNS.TOS_DT_TOP_DEPARTURE_GATE_COLUMN,
    COLUMNS.TOS_DT_TOP_IN_DELAY_COLUMN,
    COLUMNS.TOS_DT_TOP_IN_DELAY_SAVINGS_COLUMN,
    COLUMNS.TOS_DT_TOP_OFF_DELAY_COLUMN,
    COLUMNS.TOS_DT_TOP_OFF_DELAY_SAVINGS_COLUMN,
    COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN,
    COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN,
    COLUMNS.TOS_DT_TOP_RELATIVE_TRAJECTORY_COST_COLUMN,
    COLUMNS.TOS_DT_TOP_ROUTE_TYPE_COLUMN,
    COLUMNS.TOS_DT_TOP_RTC_NUM_COLUMN
];

/**
 * Returns an array containing all data table filter column labels in
 * alphanumeric order.
 *
 * @return {string[]} An array containing all filter column field labels
 */
export function getDataTableFilterFieldLabels() {
    return getFilterFieldLabels(DATA_TABLE_FILTER_COLUMNS);
}

/**
 * Returns an array containing all filter column labels in alphanumeric
 * order.
 *
 * @param (*} columnList  The list of columns from which to extract the label
 *
 * @return {string[]} An array containing all filter column field labels
 */
function getFilterFieldLabels(columnList) {
    var labelArray = columnList.map((obj) => {
        return obj.label;
    });

    // This collator should provide alphanumeric sorting based on English.
    // With the current options it will take into account only the base letter
    // (i.e. no case or accent), and will take numbers into account.
    const collator = new Intl.Collator('en',
        {numeric: true, sensitivity: 'base'});

    const sortedArray = labelArray.sort((a, b) => {
        return collator.compare(a, b);
    });

    return sortedArray;
}
