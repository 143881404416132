import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateTime } from "luxon";

import { addShowMeFlights } from "../../actions";
import { ALERT_TYPE_CANDIDATE } from "../../constants/Constants";

/**
 * Serves as a modal dialog for alerting the user about candidate flights
 */
class CandidateAlertModal extends Component
{
    static propTypes = {
        // The callback function to indicate acknowledgement; passed in
        onAck: PropTypes.func.isRequired,

        // Callsigns and trigger fields of flights to alert; from Redux
        candAlertFlightList: PropTypes.arrayOf(
                            PropTypes.objectOf(PropTypes.string)).isRequired,

        // Flags to indicate which alerts are triggerable; from Redux
        showCandidate: PropTypes.bool.isRequired,

        // Action creator to add flights to the Show Me table; from Redux
        addShowMeFlights: PropTypes.func.isRequired,

        // Current Show Me alert flight gufis; from Redux
        alertGufis: PropTypes.arrayOf(PropTypes.string),
    };

    /**
     * Constructs the CandidatesAlertModal class. This is used to alert the user to
     * flights of interest based on being candidate for CDDR.
     *
     * @param {*}    props
     * @param {func} props.onAck   function to call to acknowledge flights
     */
    constructor(props)
    {
        super(props);
        this.state = {
            candidateFlightList: [],
        };
    }

    /**
     * Calls the callback function to acknowledge the flights have been seen.
     */
    clearAlert = () => {
        this.props.onAck(this.state.candidateFlightList);
        this.setState({
          candidateFlightList: [],
        });
    }

    /**
     * Calls the callback function to acknowledge the flights have been seen and
     * adds the alerted flights to the list for the Show Me table.
     */
    showFlight = () => {
        const allGufis = this.state.candidateFlightList.map(alertRec => alertRec.gufi);
        if (this.props.alertGufis && this.props.alertGufis.length)
        {
            const newGufis = allGufis.filter(alertGufi => 
                !this.props.alertGufis.includes(alertGufi));
            if (newGufis.length)
            {
                this.props.addShowMeFlights(newGufis);
            }
        }
        else
        {
            this.props.addShowMeFlights(allGufis);
        }

        this.clearAlert();
    }

    /**
     * If this component did update, then make the appropriate changes to
     * the list.
     *
     * @param {object} prevProps   the props object before the update
     */
    componentDidUpdate(prevProps)
    {
        // only make updates if properties changed, so we don't loop when
        // setting state.
        if (this.props !== prevProps)
        {
            // Save the alerted flights
            this.setState({
                candidateFlightList: this.props.candAlertFlightList,
            });
        }
    }

    /**
     * Displays the ReactModal with our options and content. It will actually
     * only be open and shown if there are flights in the list to show.
     *
     * @return {JSX.element} A single modal dialog for rendering
     */
    render()
    {
        let showModal = this.state.candidateFlightList?.length;

        if (showModal)
        {
            const nowHhmm = DateTime.utc().set({ seconds: 0, milliseconds: 0 });
            return (
                <Modal show={true} onHide={this.clearAlert} scrollable={true} size="md">
                    <Modal.Header>
                        <Modal.Title>Candidate Reroute Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {
                        <ListGroup variant="flush" id="filterFlightAlerts">
                            { this.state.candidateFlightList.map((flight) => {
                                const flightEobt = DateTime.fromISO(flight.info.eobt).set({ seconds: 0, milliseconds: 0});
                                const minDiff = flightEobt.diff(nowHhmm, "minutes").as("minutes");

                                return (
                                    <ListGroup.Item key={flight.acid}
                                        className="candidate-flight-alert--color"
                                    >
                                      <div className="candidate-info">
                                        <div className="candidate-info-top-line">
                                          {flight.acid} to {flight.info.dest} &mdash; Push in {minDiff}min
                                        </div>
                                        <div>OFF Time:</div>
                                        <div>TOS</div>
                                        <div className="alert-value">{flight.info.topOff}min</div>
                                        <div>&nbsp;</div>
                                        <div>Filed</div>
                                        <div className="alert-value">{flight.info.off}min</div>
                                        <div>IN Time:</div>
                                        <div>TOS Sched</div>
                                        <div className="alert-value">{flight.info.topIn}min</div>
                                        <div>&nbsp;</div>
                                        <div>Filed Sched</div>
                                        <div className="alert-value">{flight.info.in}min</div>
                                      </div>
                                    </ListGroup.Item>
                                )
                              })
                            }
                        </ListGroup>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showFlight}>
                            Show
                        </Button>
                        <Button variant="secondary" onClick={this.clearAlert}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else
        {
            return null;
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state  The current Redux state
 *
 * @return {object} The desired Redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    return {
        candAlertFlightList : state.modalReducer.alertFlights.filter((flt) => flt.type === ALERT_TYPE_CANDIDATE),
        showCandidate : state.modalReducer.alertTypes.candidateVisual,
        alertGufis: state.showMeReducer.alertGufis,
    };
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addShowMeFlights,
};

export default connect(mapStateToProps, mapDispatchToProps) (CandidateAlertModal);
