import $ from "jquery";
import { DateTime } from "luxon";

import { CONDITION_EQUALS,
         CONDITION_NOT_EQUALS,
         CONDITION_GREATER_THAN,
         CONDITION_GREATER_THAN_EQUALS,
         CONDITION_LESS_THAN,
         CONDITION_LESS_THAN_EQUALS,
         CONDITION_BETWEEN,
         CONDITION_ONE_OF,
         BLACK_CSS,
         WHITE_CSS } from "../constants/ColorAlertConstants";
import { COLUMNS } from "../constants/ColumnField";
import { TIME_TO_EXPIRATION_UNSET } from "../constants/TableConstants";
import { store } from "../utils//store";

/**
 * This file contains the methods to color-code the table cells based on
 * color alerts the user sets up.  The methods are listed in the order of
 * the field options in ColorAlertConstants.
 */

/********************
 * PER-FIELD METHODS
 *******************/

/**
 * Colors the cell background based on the Coordination State value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Coordination State from the cell
 */
export function colorCoordinationState(cellNode, cellData)
{
    colorCellForEnum(cellNode, cellData,
        COLUMNS.TOS_DT_COORDINATION_STATE_COLUMN.key);
}

/**
 * Colors the cell background based on the Eligibility State value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Eligibility State from the cell
 */
export function colorEligibilityState(cellNode, cellData)
{
    colorCellForEnum(cellNode, cellData,
        COLUMNS.TOS_DT_ELIGIBILITY_STATE_COLUMN.key);
}

/**
 * Colors the cell background based on the EOBT value.
 *
 * @param {node}   cellNode    the table cell
 * @param {object} cellData    the EOBT value from the cell
 */
export function colorEobt(cellNode, cellData)
{
    colorCellForTime(cellNode, cellData, COLUMNS.EOBT_COLUMN.key);
}

/**
 * Colors the cell background based on the ETOT value.
 *
 * @param {node}   cellNode    the table cell
 * @param {object} cellData    the ETOT value from the cell
 */
export function colorEtot(cellNode, cellData)
{
    colorCellForTime(cellNode, cellData,
        COLUMNS.TOS_DT_ESTIMATED_TAKE_OFF_TIME_COLUMN.key);
}

/**
 * Colors the cell background based on the Flight Status value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Flight Status from the cell
 */
export function colorFlightStatus(cellNode, cellData)
{
    colorCellForEnum(cellNode, cellData, COLUMNS.FLIGHT_STATUS_COLUMN.key);
}

/**
 * Colors the cell background based on the IN Delay value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the IN Delay value from the cell
 */
export function colorInDelay(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData, COLUMNS.TOS_DT_IN_DELAY_COLUMN.key);
}

/**
 * Colors the cell background based on the OFF Delay value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the OFF Delay value from the cell
 */
export function colorOffDelay(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_OFF_DELAY_COLUMN.key);
}

/**
 * Colors the cell background based on the Time To Expiration value.
 * The "N/A" value is not colored.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Time To Expiration value from the cell
 */
export function colorTimeToExpiration(cellNode, cellData)
{
    if (cellData && (cellData !== TIME_TO_EXPIRATION_UNSET))
    {
        colorCellForNumeric(cellNode, cellData,
            COLUMNS.TOS_DT_TIME_TO_EXPIRATION_COLUMN.key);
    }
}

/**
 * Colors the cell background based on the Top Additional Nm value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Add Nm value from the cell
 */
export function colorTopAdditionalNm(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_ADDITIONAL_NM_COLUMN.key);
}

/**
 * Colors the cell background based on the Top Aggregate Delay Savings Airport
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Agg Del Sav <airport> value from the cell
 */
export function colorTopAggDelSavAirport(cellNode, cellData)
{
    // "1" is for the number of decimal places to round the value to
    // for comparisons; this is consistent with the format specification
    // in ColumnField
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top Aggregate Delay Savings TRACON
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Agg Del Sav <tracon> value from the cell
 */
export function colorTopAggDelSavTracon(cellNode, cellData)
{
    // "1" is for the number of decimal places to round the value to
    // for comparisons; this is consistent with the format specification
    // in ColumnField
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top Aggregate Delay Savings Fleet
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Agg Del Sav <fleet> value from the cell
 */
export function colorTopAggDelSavFleet(cellNode, cellData)
{
    // "1" is for the number of decimal places to round the value to
    // for comparisons; this is consistent with the format specification
    // in ColumnField
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top Aggregate Delay Savings Carrier 1
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Agg Del Sav <carr1> value from the cell
 */
export function colorTopAggDelSavCarrier1(cellNode, cellData)
{
    // "1" is for the number of decimal places to round the value to
    // for comparisons; this is consistent with the format specification
    // in ColumnField
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top Aggregate Delay Savings Carrier 2
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Agg Del Sav <carr2> value from the cell
 */
export function colorTopAggDelSavCarrier2(cellNode, cellData)
{
    // "1" is for the number of decimal places to round the value to
    // for comparisons; this is consistent with the format specification
    // in ColumnField
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top IN Delay value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top IN Delay value from the cell
 */
export function colorTopInDelay(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_IN_DELAY_COLUMN.key);
}

/**
 * Colors the cell background based on the Top IN Delay Savings value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top IN Del Sav value from the cell
 */
export function colorTopInDelSav(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_IN_DELAY_SAVINGS_COLUMN.key);
}

/**
 * Colors the cell background based on the Top OFF Delay value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top OFF Delay value from the cell
 */
export function colorTopOffDelay(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_OFF_DELAY_COLUMN.key);
}

/**
 * Colors the cell background based on the Top OFF Delay Savings value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top OFF Del Sav value from the cell
 */
export function colorTopOffDelSav(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_OFF_DELAY_SAVINGS_COLUMN.key);
}

/**
 * Colors the cell background based on the Top Probability Delay Savings > RTC
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Prob Del Sav > RTC value from the cell
 */
export function colorTopProbDelSavRtc(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top Probability Delay Savings > 0
 * value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top Prob Del Sav > 0 value from the cell
 */
export function colorTopProbDelSavZero(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN.key, 1);
}

/**
 * Colors the cell background based on the Top Relative Trajectory Cost value.
 *
 * @param {node}   cellNode    the table cell
 * @param {string} cellData    the Top RTC value from the cell
 */
export function colorTopRtc(cellNode, cellData)
{
    colorCellForNumeric(cellNode, cellData,
        COLUMNS.TOS_DT_TOP_RELATIVE_TRAJECTORY_COST_COLUMN.key);
}

/*******************
 * UTILITY METHODS
 ******************/

/**
 * Colors the cell background based for fields with an enum data type.
 *
 * @param {node}   cellNode   the table cell
 * @param {string} cellData   the enum value from the cell
 * @param {string} fieldKey   "key" property of the field to be colored
 */
function colorCellForEnum(cellNode, cellData, fieldKey)
{
    if (cellData)
    {
        const colorSpec = getColorForEnum(cellData, fieldKey);
        applyColorToCell(colorSpec, cellNode);
    }
}

/**
 * Colors the cell background based for fields with a luxon DateTime data type.
 *
 * @param {node}   cellNode   the table cell
 * @param {object} cellData   the date-time from the cell
 * @param {string} fieldKey   "key" property of the field to be colored
 */
function colorCellForTime(cellNode, cellData, fieldKey)
{
    if (cellData && (cellData instanceof DateTime))
    {
        const colorSpec = getColorForTime(cellData, fieldKey);
        applyColorToCell(colorSpec, cellNode);
    }
}

/**
 * Colors the cell background based for fields with a numeric data type.
 *
 * @param {node}   cellNode   the table cell
 * @param {string} cellData   the value from the cell
 * @param {string} fieldKey   "key" property of the field to be colored
 * @param {number} numDec     optional field for float data types to set the
 *                            number of decimal places to round the value to
 */
function colorCellForNumeric(cellNode, cellData, fieldKey, numDec)
{
    // Don't test using "if (cellData)" because that excludes 0
    if ((cellData !== null) && (cellData !== undefined) && !isNaN(cellData) &&
        (cellData !== ""))
    {
        const colorSpec = getColorForNumeric(cellData, fieldKey, numDec);
        applyColorToCell(colorSpec, cellNode);
    }
}

/**
 * Gets the color specification for a field with an enum data type.
 *
 * @param {string} fieldValue   the enum value from the cell
 * @param {string} fieldKey     "key" property of the field to be colored
 *
 * @return {object} an object containing the foreground and background color:
 *                      "{textColor: <textColor>, 
 *                        backgroundColor: <backgroundColor>}"
 *                  where the colors are formatted as a css-friendly string:
 *                  "rgb(r:<red>,g:<green>,b:<blue>)"; otherwise this returns
 *                  undefined if the matchingAlert is undefined
 */
// This function is exported because it is needed by the FlightAlertModal
export function getColorForEnum(fieldValue, fieldKey)
{
    const fieldAlerts = getFieldAlerts(fieldKey);
    if (fieldAlerts.length > 0)
    {
        const matchingAlert = fieldAlerts.find(alert => {
             switch(alert.condition)
             {
                 case CONDITION_EQUALS:
                     if (fieldValue === alert.value)
                     {
                         return true;
                     }
                     else
                     {
                         return false;
                     }
                 case CONDITION_NOT_EQUALS:
                     if (fieldValue !== alert.value)
                     {
                         return true;
                     }
                     else
                     {
                         return false;
                     }
                 case CONDITION_ONE_OF:
                     if (alert.valueOneOf.includes(fieldValue))
                     {
                         return true;
                     }
                     else
                     {
                         return false;
                     }
                 default:
                     console.error("Unhandled condition:", alert.condition);
                     return false;
             }
        });
        return getCssColor(matchingAlert);
    }
    return;
}

/**
 * Gets the color specification for a field with a luxon DateTime data type.
 *
 * @param {object} fieldValue   the date-time value from the cell
 * @param {string} fieldKey     "key" property of the field to be colored
 *
 * @return {object} an object containing the foreground and background color:
 *                      "{textColor: <textColor>, 
 *                        backgroundColor: <backgroundColor>}"
 *                  where the colors are formatted as a css-friendly string:
 *                  "rgb(r:<red>,g:<green>,b:<blue>)"; otherwise this returns
 *                  undefined if the matchingAlert is undefined
 */
function getColorForTime(fieldValue, fieldKey)
{
    if (fieldValue && (fieldValue instanceof DateTime))
    {
        const fieldAlerts = getFieldAlerts(fieldKey);
        if (fieldAlerts.length > 0)
        {
            // Ignore the seconds and milliseconds
            const valueHhmm = fieldValue.set({ seconds: 0, milliseconds: 0 });
            const nowHhmm = DateTime.now().toUTC().
                set({ seconds: 0, milliseconds: 0 });
            const minDiff = valueHhmm.diff(nowHhmm, "minutes");

            const matchingAlert = fieldAlerts.find(alert => {
                 const alertValue = alert.value * 1;
                 switch(alert.condition)
                 {
                     case CONDITION_EQUALS:
                         if (minDiff.minutes === alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_NOT_EQUALS:
                         if (minDiff.minutes !== alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_GREATER_THAN:
                         if (minDiff.minutes > alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_GREATER_THAN_EQUALS:
                         if (minDiff.minutes >= alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_LESS_THAN:
                         if (minDiff.minutes < alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_LESS_THAN_EQUALS:
                         if (minDiff.minutes <= alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_BETWEEN:
                     {
                         const alertValue2 = alert.value2 * 1;
                         if ((alertValue <= minDiff.minutes) &&
                             (minDiff.minutes < alertValue2))
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     }
                     default:
                         console.error("Unhandled condition:", alert.condition);
                         return false;
                 }
            });
            return getCssColor(matchingAlert);
        }
    }
    return;
}

/**
 * Gets the color specification for a field with a numeric data type.
 *
 * @param {string} fieldValue   the value from the cell
 * @param {string} fieldKey     "key" property of the field to be colored
 * @param {number} numDec       optional field for float data types to set the
 *                              number of decimal places to round the value to
 *
 * @return {object} an object containing the foreground and background color:
 *                      "{textColor: <textColor>, 
 *                        backgroundColor: <backgroundColor>}"
 *                  where the colors are formatted as a css-friendly string:
 *                  "rgb(r:<red>,g:<green>,b:<blue>)"; otherwise this returns
 *                  undefined if the matchingAlert is undefined
 */
function getColorForNumeric(fieldValue, fieldKey, numDec)
{
    // Don't test using "if (fieldValue)" because that excludes 0
    if ((fieldValue !== null) && (fieldValue !== undefined) && 
        !isNaN(fieldValue) && (fieldValue !== ""))
    {
        const fieldAlerts = getFieldAlerts(fieldKey);
        if (fieldAlerts.length > 0)
        {
            // Convert it to a number
            let dataValue;
            if (numDec === undefined)
            {
                dataValue = fieldValue * 1;
            }
            else
            {
                dataValue = Number(fieldValue).toFixed(numDec);
            }

            const matchingAlert = fieldAlerts.find(alert => {
                 const alertValue = alert.value * 1;
                 switch(alert.condition)
                 {
                     case CONDITION_EQUALS:
                         if (dataValue === alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_NOT_EQUALS:
                         if (dataValue !== alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_GREATER_THAN:
                         if (dataValue > alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_GREATER_THAN_EQUALS:
                         if (dataValue >= alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_LESS_THAN:
                         if (dataValue < alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_LESS_THAN_EQUALS:
                         if (dataValue <= alertValue)
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     case CONDITION_BETWEEN:
                     {
                         const alertValue2 = alert.value2 * 1;
                         if ((alertValue <= dataValue) &&
                             (dataValue < alertValue2))
                         {
                             return true;
                         }
                         else
                         {
                             return false;
                         }
                     }
                     default:
                         console.error("Unhandled condition:", alert.condition);
                         return false;
                 }
            });
            return getCssColor(matchingAlert);
        }
    }
    return;
}

/**
 * Returns a css-friendly color string for the color in the given alert.
 *
 * @param {object} matchingAlert  the alert that applies to the cell; may be
 *                                undefined
 *
 * @return {object} an object containing the foreground and background color:
 *                      "{textColor: <textColor>, 
 *                        backgroundColor: <backgroundColor>}"
 *                  where the colors are formatted as a css-friendly string:
 *                  "rgb(r:<red>,g:<green>,b:<blue>)"; otherwise this returns
 *                  undefined if the matchingAlert is undefined
 */
// This is exported because ColorAlertsModal also uses it
export function getCssColor(matchingAlert)
{
    if (matchingAlert)
    {
        const backgroundColor = "rgb(" + matchingAlert.rgb.r + ", " +
            matchingAlert.rgb.g + ", " +
            matchingAlert.rgb.b + ")";
        const textColor = matchingAlert.whiteText ? WHITE_CSS : BLACK_CSS;

        const colorSpec = {textColor, backgroundColor};

        return colorSpec;
    }
    return;
}

/**
 * Applies the color from the alert to the cell if the alert is defined.
 *
 * @param {object} colorSpec  an object containing the foreground and 
 *                            background color:
 *                              "{textColor: <textColor>, 
 *                                backgroundColor: <backgroundColor>}"
 *                            where the colors are formatted as a css-friendly 
 *                            "rgb(r:<red>,g:<green>,b:<blue>)" 
 * @param {node}   cellNode   the table cell
 */
function applyColorToCell(colorSpec, cellNode)
{
    if (colorSpec && cellNode)
    {
        $(cellNode).css("color", colorSpec.textColor);
        $(cellNode).css("background-color", colorSpec.backgroundColor);
    }
}

/**
 * Gets the alerts for the given field.
 *
 * @param {string} fieldKey   the "key" property of a field
 *
 * @return {arrayOf{object}} the array of alerts for this field; may be empty
 */
function getFieldAlerts(fieldKey)
{
    const allAlerts = [ ...store.getState().modalReducer.colorAlerts ];

    return allAlerts.filter(alert => alert.field === fieldKey);
}
