export const COLUMN_TYPES = {
    ALPHA_NUM: "alphaNum",
    BOOLEAN: "boolean",
    COORD_STATE: "coordState",
    DEPARTURE_FIX: "departureFix",
    DEPARTURE_GATE: "departureGate",
    ELIGIBILITY_STATE: "eligibilityState",
    FLIGHT_STATUS: "flightStatus",
    HHMM: "HHmm",
    RUNWAY: "runway",
    SWIM_STATE: "swimState",
    TIME_TO_EXPIRATION: "timeToExpiration",
    // NON_EMPTY_STRING: type used to sort data, ensuring,
    // that empty strings are placed at the bottom of the datatable page
    NON_EMPTY_STRING: "nonEmptyString",
// built-in types included here for consistent access
    NUM: "num",
    STRING: "string",
};
