import axios, { Cancel, isCancel } from "axios";

import { DATA_URL, COGNITO_AUTH_ENABLED, STORAGE_JWT_COOKIE } from "../constants/Constants";
import { iataToIcaoSimple } from "./airportUtils"

const ERROR_MSG_AUTH_TOKEN_UNAVAILABLE = 'auth token is not ready yet';

/**
 * This file provides methods for accessing TOS data on the server.
 */

// create an axios instance for accessing TOS data api
const dataAxios = axios.create({
    withCredentials: process.env.REACT_APP_HTTP_REQUEST_WITH_CREDENTIALS == 'true',
    baseURL: DATA_URL,
});

if( process.env.REACT_APP_DIP_PLATFORM_VERSION == 'true') {
    dataAxios.interceptors.request.use(function (config) {
        const token = localStorage.getItem(STORAGE_JWT_COOKIE);
        if( COGNITO_AUTH_ENABLED === 'true' ) {
            if( token ) {
                config.headers.Authorization = "Bearer " + token;
            } else {
                let msg = `Cancelling the request - ${ERROR_MSG_AUTH_TOKEN_UNAVAILABLE}: ${JSON.stringify(config)}`;
                throw new Cancel(msg);
            }
         }
        return config;
     });
}

export const tosClient = {
    getConfiguration,
    getDepartureAll,
    getDepartureGufi,
    getFlightRoutes,
    getTosRuns,
    getTosStatus,
    postAtcUpdate,
    postFlightUpdate,
    postOperatorUpdate,
    postOperatorArrayUpdate,
    postTosStatus,
    ERROR_MSG_AUTH_TOKEN_UNAVAILABLE
}

/**
 * Gets the configuration for all defined airports, or a select airport.
 *
 * @param {string} [airport]    airport to limit configurations returned;
 *                              optional
 *
 * @return {Promise} the configuration data if success, else an error message
 */
async function getConfiguration(airport)
{
    const params = new URLSearchParams();
    let url = "airspace/";
    if (airport)
    {
        // If this is a single airport
        if( !airport.includes( "," ) )
        {
            if (airport.length === 3)
            {
                airport = iataToIcaoSimple( airport );
            }
            params.append("loc", airport);
            url += "airport/" + airport;
        }
        // Otherwise it's a list of airports
        else
        {
            let airportList = airport.split(",");
            let adjustedAirports = "";
            for( let singleAirport of airportList )
            {
                if (singleAirport.length === 3)
                {
                    singleAirport = iataToIcaoSimple( singleAirport );
                }
                adjustedAirports += singleAirport + ',';
            }
            adjustedAirports = adjustedAirports.slice(0, -1);
            params.append("loc", adjustedAirports);
            url += "airports/" + adjustedAirports;
        }
    }
    else
    {
        url += "all";
    }

    try {
        const response = await dataAxios.get(url, { params: params });
        return Promise.resolve(response.data);
    }
    catch(ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        const errMsg = "Could not retrieve " + (airport ? airport : "all") + 
            " airspace data.  Please contact support.";
        console.error("Error retrieving configuration records for airport=",
             airport, "from", (DATA_URL + url), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Gets all departure flight data for the given role.
 *
 * @param {object} params            parameters for making this server call
 * @param {string} params.role       role name
 * @param {string} params.carrier    carrier
 * @param {string} params.airports   optional airport for filtering departures
 * @param {number} params.timestamp  optional timestamp for historical data, 
 *                                   in millis
 *
 * @return {Promise} all applicable departure flight data if success, else an 
 *                   error message
 */
async function getDepartureAll(params)
{
    let path = "departure/all";
    const urlParams = new URLSearchParams();
    urlParams.append("role", params.role);
    urlParams.append("carrier", params.carrier);
    if (params.airports)
    {
        let airportParams = "";
                                         
        for (let idx = 0; idx < params.airports.length; idx++)
        {   
            if(airportParams.length > 0)
            {
                airportParams = airportParams.concat(",")
            }       
        
            if (params.timestamp && (params.airports[idx].length === 3))
            {
                airportParams = airportParams.concat("K" + params.airports[idx]);
            }
            else
            {
                airportParams = airportParams.concat(params.airports[idx]);
            }
        }
        
        urlParams.append("loc", airportParams);
    }
    if (params.timestamp)
    {
        urlParams.append("timestamp", params.timestamp);
        path = "departure/history/all";
    }

    try 
    {
        const serverResponse = await dataAxios.get(path, {
            params: urlParams
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        let errMsg = 
            "Could not retrieve departure data. Please contact support.";
        if (ex.response)
        {
            if (ex.response.status === 404)
            {
                // Don't want to error just because no data is currently 
                // available
                console.log("No records found.");
                return Promise.resolve([]);
            }
            else if ((ex.response.status === 403) || 
                     (ex.response.status === 401))
            {
                errMsg = `Authorization error! ${ex.response.data}`;
            }
        }

        console.error("Error retrieving departure records for",
            paramsToString(params), "from", (DATA_URL + path), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Gets the departure record for the given gufi and role.
 *
 * @param {object} params           parameters for selecting departure record
 * @param {string} params.role      role name
 * @param {string} params.carrier   carrier
 * @param {string} params.gufi      flight gufi
 * @param {number} params.timestamp optional timestamp for historical record, 
 *                                  in millis
 *
 * @return {Promise} the departure data if success, else an error message
 */
async function getDepartureGufi(params)
{
    let path = "departure/gufi/" + params.gufi;
    const urlParams = new URLSearchParams();
    urlParams.append("role", params.role);
    urlParams.append("carrier", params.carrier);
    if (params.timestamp)
    {
        urlParams.append("timestamp", params.timestamp);
        path = "departure/history/gufi/" + params.gufi;
    }
    try 
    {
        const serverResponse = await dataAxios.get(path, {
            params: urlParams
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        let errMsg = "Could not retrieve flight data for " + params.gufi +
            ". Please contact support.";
        if (ex.response)
        {
            if (ex.response.status === 404)
            {
                // Don't want to error just because no data is currently 
                // available
                console.log("No records found for " + params.gufi);
                return Promise.resolve([]);
            }
            else if ((ex.response.status === 403) || 
                     (ex.response.status === 401))
            {
                errMsg = `Authorization error! ${ex.response.data}`;
            }
        }

        console.error("Error retrieving departure records for", 
            paramsToString(params), "from", (DATA_URL + path), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Gets the flight menu records for the given gufis and role.
 *
 * @param {object} params             selection parameters
 * @param {string} params.role        role name
 * @param {string} params.carrier     carrier name
 * @param {string[]} params.gufiList  list of flight gufis
 * @param {number} params.timestamp   optional timestamp for historical record, 
 *                                    in millis
 *
 * @return {Promise} the departure data if success, else an error message
 */
async function getFlightRoutes(params)
{
    let path = "flight";
    const urlParams = new URLSearchParams();
    urlParams.append("role", params.role);
    urlParams.append("carrier", params.carrier);
    urlParams.append("gufiList", params.gufiList.join(','));

    if (params.timestamp)
    {
        urlParams.append("timestamp", params.timestamp);
        path = "flight/history";
    }

    try {
        const serverResponse = await dataAxios.get(path, {
            params: urlParams
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        let errMsg = "Could not retrieve route data for " + params.gufi +
            ". Please contact support.";
        if (ex.response)
        {
            if (ex.response.status === 404)
            {
                // Don't want to error just because no data is currently 
                // available
                console.log("No records found for " + params.gufi);
                return Promise.resolve([]);
            }
            else if ((ex.response.status === 403) || 
                     (ex.response.status === 401))
            {
                errMsg = `Authorization error! ${ex.response.data}`;
            }
        }

        console.error("Error retrieving departure records for", 
            paramsToString(params), "from", (DATA_URL + path), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Gets the TOS mode status information.
 *
 * @param {number} timestamp   optional timestamp for historical records, 
 *                             in millis
 *
 * @return {Promise} the TOS status if success, else an error message
 */
async function getTosStatus(timestamp)
{
    console.log("get tos status")
    const urlParams = new URLSearchParams();
    let path = "tos-status";
    if (timestamp)
    {
        urlParams.append("timestamp", timestamp);
        path = "tos-status/history";
    }

    try {
        const serverResponse = await dataAxios.get(path, {
            params: urlParams
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        let errMsg = "Could not retrieve TOS data. Please contact support.";
        if (ex.response)
        {
            if (ex.response.status === 404)
            {
                errMsg = `No current status found.`;
            }
        }
        console.error(`Error retrieving TOS status from ${DATA_URL}.`, ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Posts the ATC TOS status mode update.  Note that if the TOS approver is
 * changed for an airport, the approvalTypes will contain the data for just
 * that airport, not all airports.
 *
 * @param {string} role          role name
 * @param {string} roleUser      role user name
 * @param {object} updateStatus  new tos status records
 * @param {array}  updateStatus.approvalTypes   approval type for each airport
 * @param {string} updateStatus.approvalTypes[i].airport       airport name
 * @param {string} updateStatus.approvalTypes[i].approvalType  TOWER or Center
 * @param {boolean}updateStatus.approvalTypes[i].active        true for active 
 *                                                             TOS mode
 *
 * @return {Promise} the TOS status if success, else an error message
 */
async function postTosStatus(role, roleUser, updateStatus)
{
    const requestBody = {
        source: roleUser,
        sourceType: role,
        tosStatus: updateStatus,
    };

    try {
        const serverResponse = await dataAxios.post("tos-status", requestBody,
        {
            params: {
                role: role,
                roleUser: roleUser,
            }
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        const errMsg = 
            "Could not update TOS mode status. Please contact support";
        console.error("Error updating TOS mode status for", role, roleUser,
            "at", (DATA_URL + "tos-status"), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Gets the array of TOS run times in the recent past.
 *
 * @return {Promise} TOS recent run times or an error message
 */
async function getTosRuns()
{
    try {
        const serverResponse = await dataAxios.get("tos-runs");
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        let errMsg = 
            "Could not retrieve TOS run times. Please contact support.";
        if (ex.response)
        {
            if (ex.response.status === 404)
            {
                errMsg = "No recent runs found.";
            }
        }
        console.error("Error obtaining TOS run times from", 
            (DATA_URL + "tos-runs"), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Posts the flight operator TOS update.
 *
 * @param {string} role          role name
 * @param {string} roleUser      role user name
 * @param {string} tosId         TOS id to act upon
 * @param {string} routeId       TOS route option name to act upon
 * @param {string} action        action to take, for example REQUEST_TOS_ROUTE
 *
 * @return {Promise} the flight data if success or an error message if failure
 */
async function postOperatorUpdate(role, roleUser, tosId, routeId, action)
{
    try {
        const serverResponse = await dataAxios.post("update/operator", {
            source: roleUser,
            sourceType: role,
            tosOperatorDataList: [{
                tosId: tosId,
                tosRouteId: routeId,
                tosOperatorAction: action.toUpperCase(),
            }],
        }, {
            params: {
                role: role,
                roleUser: roleUser,
            }
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        console.error("Error requesting operator update from",
            (DATA_URL + "update/operator"), "role=", role, 
            "roleUser=", roleUser, "tosId=", tosId, "routeId=", routeId,
            "action=", action, "::", ex); 
        return Promise.reject("Could not post update for " + routeId);
    }
}

/**
 * Posts a list of flight operator TOS updates.
 *
 * @param {string} role          role name
 * @param {string} roleUser      role user name
 * @param {string} tosId         TOS id to act upon
 * @param {array} updates        array of routeId and action updates
 *
 * @return {Promise} the flight data if success or an error message if failure
 */
async function postOperatorArrayUpdate(role, roleUser, tosId, updates)
{
    try {
        const serverResponse = await dataAxios.post("update/operator", {
            source: roleUser,
            sourceType: role,
            tosOperatorDataList: updates.map((data) => ({
                tosId: tosId,
                tosRouteId: data.routeId,
                tosOperatorAction: data.action.toUpperCase(),
            })),
        }, {
            params: {
                role: role,
                roleUser: roleUser,
            }
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }

        console.error("Error requesting operator update from",
            (DATA_URL + "update/operator"), "role=", role,
            "roleUser=", roleUser, "updates=", updates, "::", ex);
        return Promise.reject("Could not post swim update for " + tosId);
    }
}

/**
 * Posts the ATC TOS update.
 *
 * @param {string} role          role name
 * @param {string} roleUser      role user name
 * @param {string} tosId         TOS id to act upon
 * @param {string} routeId       TOS route option name to act upon
 * @param {string} action        action to take, for example APPROVE_TOS_ROUTE
 *
 * @return {Promise} the flight data if success or an error message if failure
 */
async function postAtcUpdate(role, roleUser, tosId, routeId, action)
{
    const requestBody = [{
        tosId: tosId,
        tosRouteId: routeId,
        tosAtcAction: action.toUpperCase(),
    }];

    try {
        const serverResponse = await dataAxios.post("update/atc", {
            source: roleUser,
            sourceType: role,
            tosAtcDataList: requestBody,
        }, {
            params: {
                role: role,
                roleUser: roleUser,
            }
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        console.error("Error requesting ATC update from",
            (DATA_URL + "update/atc"), "for", role, roleUser, 
            "tosId=", tosId, "routeId=", routeId, "action=", action, "::", ex); 
        return Promise.reject("Could not post update for " + routeId);
    }
}

/**
 * Posts the flight update with the given information from the user.
 *
 * @param {string} role                role name
 * @param {string} roleUser            role user name
 * @param {string} gufi                GUFI id of flight to act upon
 * @param {object} update              contains fields to update
 * @param {string} update.scratchPad   optional user scratch pad entry
 *
 * @return {Promise} a responsemessage if success or an error message if failure
 */
async function postFlightUpdate(role, roleUser, gufi, update)
{
    const requestBody = {
        gufi: gufi,
        scratchPad: update.scratchPad,
    };

    try {
        const serverResponse = await dataAxios.post(
            "update/flight", requestBody,
        {
            params: {
                role: role,
                roleUser: roleUser,
            }
        });
        return Promise.resolve(serverResponse.data);
    }
    catch (ex)
    {
        if (isCancel(ex)) {
            return Promise.reject(ex);
        }
        
        const errMsg = "Could not update flight.  Please contact support.";
        console.error("Error updating flight", gufi, "for", role, roleUser,
            "from", (DATA_URL + "update/flight"), "::", ex);
        return Promise.reject(errMsg);
    }
}

/**
 * Converts the params object into a string for logging.
 *
 * @param {object} params           parameters from request
 * @param {string} params.role      role name
 * @param {string} params.roleUser  role user name
 * @param {string} params.airport   optional airport name
 * @param {string} params.gufi      optional flight gufi 
 * @param {string} params.gufiList  optional flight gufi list
 * @param {number} params.timestamp optional timestamp for historical record, 
 *                                  in millis
 */
function paramsToString(params)
{
    let paramStr = `role=${params.role} roleUser=${params.roleUser} ` +
        `airport=${params.airport}`;

    if (params.gufi)
    {
        paramStr += ` gufi=${params.gufi}`;
    }

    if (params.gufiList)
    {
        paramStr += ` gufiList=${params.gufiList}`;
    }

    if (params.timestamp)
    {
        paramStr += ` timestamp=${params.timestamp}/`;
        const dateForm = new Date();
        dateForm.setTime(params.timestamp);
        paramStr += dateForm.toISOString();
    }

    return paramStr;
}
