import $ from "jquery";
import { DateTime } from "luxon";

// searchBuilder seems to expect render to always return a string, but we want
// to use DateTime for filtering, so we need to add a replace method to avoid
// errors.  This is kind-a like extending DateTime to have a replace function
// that returns itself. I didn't see anything saying render had to return a
// string. It usually would, and searchBuilder definitely expects it, so this
// gets the DateTime object to process through to the search method.
DateTime.prototype.replace = function()
{
   return this;
}

// @TODO This is not a good thing to do, and should be reinvestigated after
// SearchBuilder 1.3.1 is released.
/* eslint no-extend-native: ["error", { "exceptions": ["Number"]}] */
Number.prototype.replace = function()
{
    return this;
}

/**
 * Generates the searchBuilder condition configuration methods for enum
 * "equals".
 *
 * @param {String[]} values  array of internal filter values for comparisons
 * @param {String[]} texts   array of table display values to show in the list
 *
 * @return condition configuration object for equals for listed values
 */
export function makeEnumConditionEquals(values, texts)
{
    return {
              // function to initialize the way to get the user values for
              // comparison.
              // that       - criteria instance this is being set on
              // fn         - callback function to call when ready to search
              // preDefined - previously selected value
        init: function(that, fn, preDefined = null) {
            // The "Value" placeholder token is not selectable
            let e1 = $("<select/>")
                .addClass(that.classes.value)
                .addClass(that.classes.dropDown)
                .addClass(that.classes.select)
                .append($("<option value='none' selected disabled hidden>Value</option>"))
                .on('input change', function () {
                    fn(that, this);
                });
            for (let i = 0; i < values.length; i++)
            {
                let opt = $("<option>", {
                    text: texts[i],
                    value: values[i],
                })
                    .addClass(that.classes.option);
                e1.append(opt);
            }
            // check preDefined against null in case we have a falsy value
            if (preDefined !== null)
            {
                e1.val(preDefined[0]).attr("selected", true);
            }

            return e1;
        },
                      // checks that the input is valid; since we have a set
                      // number of values, if one is selected, it's valid
                      // e1   - array of elements returned from init
                      // unusedInstanceToCheck - instance to check
        isInputValid: function(e1, unusedInstanceToCheck) {
            let sel = $(e1[0]).children("option:selected");
            return (sel.length === 1);
        },
        conditionName: 'Equals',
                    // function to get the user values from the element created
                    // by the init function.
                    // e1 - array of elements returned from init
        inputValue: function(e1) {
            let values = [];

            for (let element of e1)
            {
                if ($(element).is("select"))
                {
                    values.push($(element).children("option:selected").val());
                }
            }

            return values;
        },
                // function to determine if the table row matches this condition
                // value - value from the table
                // comparison - array of input comparison values from inputValue
        search: function(value, comparison) {
            return value === comparison[0];
        },
    };
}

/**
 * Generates the searchBuilder condition configuration methods for enum
 * "not equals".
 *
 * @param {String[]} values  array of internal filter values for comparisons
 * @param {String[]} texts   array of table display values to show in the list
 *
 * @return condition configuration object for not equals for listed values
 */
export function makeEnumConditionNotEquals(values, texts)
{
    return {
              // function to initialize the way to get the user values for
              // comparison.
              // that       - criteria instance this is being set on
              // fn         - callback function to call when ready to search
              // preDefined - previously selected value
        init: function(that, fn, preDefined = null) {
            // The "Value" placeholder token is not selectable
            let e1 = $("<select/>")
                .addClass(that.classes.value)
                .addClass(that.classes.dropDown)
                .addClass(that.classes.select)
                .append($("<option value='none' selected disabled hidden>Value</option>"))
                .on('input change', function () {
                    fn(that, this);
                });
            for (let i = 0; i < values.length; i++)
            {
                let opt = $("<option>", {
                    text: texts[i],
                    value: values[i],
                })
                    .addClass(that.classes.option);
                e1.append(opt);
            }
            // check preDefined against null in case we have a falsy value
            if (preDefined !== null)
            {
                e1.val(preDefined[0]).attr("selected", true);
            }

            return e1;
        },
                      // checks that the input is valid; since we have a set
                      // number of values, if one is selected, it's valid
                      // e1   - array of elements returned from init
                      // unusedInstanceToCheck - instance to check
        isInputValid: function(e1, unusedInstanceToCheck) {
            let sel = $(e1[0]).children("option:selected");
            return (sel.length === 1);
        },
        conditionName: 'Not Equals',
                    // function to get the user values from the element created
                    // by the init function.
                    // e1 - array of elements returned from init
        inputValue: function(e1) {
            let values = [];

            for (let element of e1)
            {
                if ($(element).is("select"))
                {
                    values.push($(element).children('option:selected').val());
                }
            }

            return values;
        },
                // function to determine if the table row matches this condition
                // value - value from the table
                // comparison - array of input comparison values from inputValue
        search: function(value, comparison) {
            return value !== comparison[0];
        },
    };
}

/**
 * Generates the searchBuilder condition configuration methods for enum
 * 'one of'.
 *
 * @param {String[]} values  array of internal filter values for comparisons
 * @param {String[]} texts   array of table display values to show in the list
 *
 * @return condition configuration object for one of possibly several
 *         listed values
 */
export function makeEnumConditionOneOf(values, texts)
{
    return {
              // function to initialize the way to get the user values for
              // comparison.
              // that       - criteria instance this is being set on
              // fn         - callback function to call when ready to search
              // preDefined - previously selected value
        init: function(that, fn, preDefined = null) {
            // use a multi-select input with default hidden non-value and
            // disabled first visible option for instruction hint
            let e1 = $("<select multiple />")
                .addClass(that.classes.value)
                .addClass(that.classes.dropDown)
                .addClass(that.classes.select)
                .append($("<option value='' hidden selected />"))
                .append($("<option value='' disabled>Use Ctrl or Shift key to select multiple</option>"))
                .on('input change', function () {
                    fn(that, this);
                });
            for (let i = 0; i < values.length; i++)
            {
                let opt = $("<option>", {
                    text: texts[i],
                    value: values[i],
                })
                    .addClass(that.classes.option);
                e1.append(opt);
            }
            // check preDefined against null in case we have a falsy value
            if (preDefined !== null)
            {
                e1.val(preDefined);
            }

            return e1;
        },
                      // checks that the input is valid, basically that
                      // at least one value was selected.
                      // e1   - array of elements returned from init
                      // unusedInstanceToCheck - instance to check
        isInputValid: function(e1, unusedInstanceToCheck) {
            let sel = $(e1[0]).children("option:selected");
            return (sel.length >= 1) && (sel[0].value !== "");
        },
        conditionName: 'One Of',
                    // function to get the user values from the element created
                    // by the init function.
                    // e1 - array of elements returned from init
        inputValue: function(e1) {
            let values = [];

            for (let element of e1)
            {
                if ($(element).is("select"))
                {
                    // Search Builder seems to pass through an empty string
                    // even if the isValid method calls is false, so filter out
                    // any empty strings here
                    values = $(element).val().filter((val) => {
                        return val !== "";
                    });
                }
            }
            return values;
        },
                // function to determine if the table row matches this condition
                // value - value from the table
                // comparison - array of input comparison values from inputValue
        search: function(value, comparison) {
            return comparison.includes(value);
        },
    };
}

/**
 * Generates the searchBuilder condition configuration methods for a condition
 * with a single hard-coded search function, such as comparing for one value.
 *
 * @param {string} cName       name of condition
 * @param {string} checkValue  value to compare with table entry
 *
 * @return condition configuration object to check for a single value
 */
export function makeConditionSingleCheck(cName, checkValue)
{
    return {
        // function to initialize the way to get the user values for
        // comparison, which in this case isn't used
        init: function() {
            return [];
        },
        // function to get the user values from the element created
        // by the init function.
        isInputValid: function() {
            return true;
        },
        conditionName: cName,
        // function to get the user values from the element created
        // by the init function. Not used
        inputValue: function() {
            return;
        },
        // function to determine if the table row matches this condition
        // value - value from the table
        search: function(value) {
            return (value === checkValue);
        },
    };
}

/**
 * Generates the searchBuilder condition configuration methods for a condition
 * with a single hard-coded search function, such as comparing the NOT case
 * for one value.
 *
 * @param {string} cName       name of condition
 * @param {string} checkValue  value to compare with table entry
 *
 * @return condition configuration object to check for a value which is NOT
 *         checkValue
 */
export function makeConditionSingleCheckNot(cName, checkValue)
{
    return {
              // function to initialize the way to get the user values for
              // comparison, which in this case isn't used.
        init: function() {
            return;
        },
                    // function to get the user values from the element created
                    // by the init function.
        isInputValid: function() {
            return true;
        },
        conditionName: cName,
                    // function to get the user values from the element created
                    // by the init function. Not used
        inputValue: function() {
            return "";
        },
                // function to determine if the table row matches this condition
                // value - value from the table
        search: function(value) {
            return (value !== checkValue);
        },
    };
}

/**
 * Generates the searchBuilder condition common configuration methods for
 * HHmm or other numeric fields.
 *
 * @param {number} numFields  1 or 2, how many input fields are needed
 *                            (2 for "between", otherwise 1)
 * @param {string} type       "zulu" to validate to 4 characters, or anything
 *                            else (e.g. "relative" or "numeric") to just
 *                            validate that something has been entered
 * @param {string} [hint]     placeholder text for input fields
 *
 * @return partial condition configuration object for HH:mm fields
 */
export function makeConditionBase(numFields, type, hint)
{
    const base = {
                    // function to get the user values from the element created
                    // by the init function.
                    // e1 - array of elements returned from init
        inputValue: function(e1) {
            let values = [];
            // 'e3': copy over the array that was passed in when initializing
            // the loop, just to be safe; copied from searchBuilder code
            for (let idx = 0, e3 = e1; idx < e3.length; idx++)
            {
                const elem = e3[idx];
                if ($(elem).is("input"))
                {
                    values.push($(elem).val());
                }
            }
            return values;
        },
    };

    if (numFields === 1)
    {
              // function to initialize the way to get the user values for
              // comparison, in this case just a text input field.
              // that       - criteria instance this is being set on
              // fn         - callback function to call when ready to search
              // preDefined - previously selected value
        base.init = function(that, fn, preDefined = null) {
            let e1 = makeInputElement(that, fn, hint);
            if (preDefined !== null)
            {
                $(e1).val(preDefined[0]);
            }
            return e1;
        };
    }
    else
    {
              // function to initialize the way to get the user values for
              // comparison, in this case two inputs with joiner text.
              // that       - criteria instance this is being set on
              // fn         - callback function to call when ready to search
              // preDefined - previously selected value
        base.init = function(that, fn, preDefined = null) {
            let e1 = makeInputElement(that, fn, hint);
            let e2 = $('<span>')
                    .addClass(that.classes.joiner)
                    .text(that.s.dt.i18n('searchBuilder.valueJoiner',
                        that.c.i18n.valueJoiner));
            let e3 = makeInputElement(that, fn, hint);

            if (preDefined !== null)
            {
                $(e1).val(preDefined[0]);
                $(e3).val(preDefined[1]);
            }

            return [ e1, e2, e3 ];
        };
    }

    if (type === "zulu")
    {
              // checks that the input is valid, meaning we have 4 digits to
              // make a time from
              // e1   - array of elements returned from init
              // unusedInstanceToCheck - instance to check
        base.isInputValid = function(e1, unusedInstanceToCheck) {
            let allFilled = true;
            for (let idx = 0, e2 = e1; idx < e2.length; idx++)
            {
                const elem = e2[idx]
                if ($(elem).is("input"))
                {
                    const input = $(elem).val();
                    if ((input.length !== 4) || isNaN(input))
                    {
                        allFilled = false;
                    }
                }
            }
            return allFilled;
        };
    }
    else
    {
              // checks that the input is valid, meaning we have something
              // and it's a number
              // e1   - array of elements returned from init
              // unusedInstanceToCheck - instance to check
        base.isInputValid = function(e1, unusedInstanceToCheck) {
            let allFilled = true;
            for (let idx = 0, e2 = e1; idx < e2.length; idx++)
            {
                const elem = e2[idx]
                if ($(elem).is("input"))
                {
                    const input = $(elem).val();
                    if ((input.length === 0) || isNaN(input))
                    {
                        allFilled = false;
                    }
                }
            }
            return allFilled;
        };
    }

    return base;
}

/**
 * Makes the input element for HHmm or other numeric fields.
 *
 * @param {object} that    criteria instance condition is being set on
 * @param {func}   fn      callback function when search is triggered
 * @param {string} [hint]  placeholder text for input element
 *
 * @return input element
 */
function makeInputElement(that, fn, hint)
{
    let e1 = $("<input/>")
        .addClass(that.classes.value)
        .addClass(that.classes.input)
        .on('input', function () {
            fn(that, this);
        });
    if (hint)
    {
        $(e1).attr("placeholder", hint);
    }

    return e1;
}
