import React from "react";
import PropTypes from "prop-types";

import { CONDITION_BETWEEN,
         CONDITION_ONE_OF,
         coordinationOptions,
         eligibilityOptions,
         flightStatusOptions } from "../../../constants/ColorAlertConstants";
import { COLUMN_TYPES } from "../../../constants/ColumnTypes";
import "../../../css/color-alerts.css";


/**
 * This functional component provides a user interface to provide the value(s)
 * of an alert to be color-coded.  Based on the field type and condition,
 * this user interface will display one of the following:
 * - a single data entry field
 * - two data entry fields when the condition is "Between"
 * - a drop-down list when the condition is "One Of"
 *
 *
 * @param {string} selectedFieldType     the data type of the field being color
 *                                       coded; may be undefined if the field
 *                                       hasn't been selected yet
 * @param {string} selectedCondition     the selected condition for this alert;
 *                                       may be undefined if the field or
 *                                       condition haven't been selected yet
 * @param {string} selectedValue         the previously-selected value for this
 *                                       alert; may be undefined
 * @param {func}   setSelectedValue      callback function to notify when a
 *                                       value has been selected/set
 * @param {string} selectedValue2        the previously-selected value for this
 *                                       alert if the selectedCondition is
 *                                       "Between"; may be undefined
 * @param {func}   setSelectedValue2     callback function to notify when the
 *                                       second value in a "Between" condition
 *                                       has been set
 * @param {array}  selectedValueOneOf    the previously-selected values for this
 *                                       alert if the selectedCondition is
 *                                       "One Of"; may be undefined.  If the
 *                                       suer selects a value already in this
 *                                       list, it will be removed from this list
 * @param {func}   setSelectedValueOneOf callback function to notify when a
 *                                       value in a "One Of" condition has been
 *                                       selected
 * @param {number} index                 index of the alert being edited in the
 *                                       list of alerts
 */
const ColorAlertValue = ({ selectedFieldType, selectedCondition,
    selectedValue, setSelectedValue, selectedValue2, setSelectedValue2,
    selectedValueOneOf, setSelectedValueOneOf, index }) =>
{
    /**
     * Handles the value entry event when the field type is for a single
     * numeric value or for the first value of a "Between" condition.  The
     * value is passed back to the caller.
     *
     * @param {object} event   selection event; event.target.value contains
     *                         the value
     */
    const handleValue = (event) => {
        setSelectedValue(event.target.value, index);
    };

    /**
     * Handles the second value entry event when the condition is "Between".
     * The value is passed back to the caller.
     *
     * @param {object} event   selection event; event.target.value contains
     *                         the value
     */
    const handleValue2 = (event) => {
        setSelectedValue2(event.target.value, index);
    };

    /**
     * Handles the selection when the user chooses an enum from a "One Of"
     * condition.  The value is passed back to the caller.
     *
     * @param {object} event   selection event; event.target.value contains
     *                         the value
     */
    const handleValueOneOf = (event) => {
        setSelectedValueOneOf(event.target.value, index);
    };

    // Return the contols for the enum types
    if ((selectedFieldType === COLUMN_TYPES['ELIGIBILITY_STATE']) ||
        (selectedFieldType === COLUMN_TYPES['COORD_STATE']) ||
        (selectedFieldType === COLUMN_TYPES['FLIGHT_STATUS']))
    {
        // The user has already selected a condition
        if (selectedCondition)
        {
            // Get the enum options based on the field data type
            let optionList = [];
            if (selectedFieldType === COLUMN_TYPES['ELIGIBILITY_STATE'])
            {
                optionList = [ ...eligibilityOptions ];
            }
            else if (selectedFieldType === COLUMN_TYPES['COORD_STATE'])
            {
                optionList = [ ...coordinationOptions ];
            }
            else if (selectedFieldType === COLUMN_TYPES['FLIGHT_STATUS'])
            {
                optionList = [ ...flightStatusOptions ];
            }

            // Process the "One Of" condition
            if (selectedCondition === CONDITION_ONE_OF)
            {
                // Create the description of what the user selected so far
                let chosenString = "";
                if ((selectedValueOneOf) && (selectedValueOneOf.length > 0))
                {
                    // Create a comma-separated list of what's been
                    // selected so far
                    selectedValueOneOf.forEach(elem => {
                        optionList.forEach(enumElem => {
                            if (elem === enumElem.value)
                            {
                                chosenString = chosenString + "," +
                                    enumElem.label;
                            }
                        });
                    });
                    // Trim the pre-pended comma from the first item
                    if (chosenString.length > 0)
                    {
                        chosenString = chosenString.substring(1);
                    }
                }
                else
                {
                    chosenString = "<none>";
                }

                // Return a drop-down list of options and a label of
                // what has been selected so far
                return (
                    <div>
                        <select value={"Select"}
                            onChange={handleValueOneOf}
                        >
                            <option value="Select" key="Select" disabled>
                                Select
                            </option>
                            {optionList.map(item => (
                                <option value={item.value} key={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                        <><br/>Selected: {chosenString}</>
                    </div>
                );
            }
            // Enum type, not "One Of"
            else
            {
                // Return a simple drop-down of all enum options
                const optionToSelect = selectedValue ?? "Select";
                return (
                    <div>
                        <select value={optionToSelect}
                            onChange={handleValue}
                        >
                            <option value="Select" key="Select" disabled hidden>
                                Select
                            </option>
                            {optionList.map(item => (
                                <option value={item.value} key={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            }
        }
        // The user has not selected a condition for the enum
        else
        {
            // Return a "Value" drop-down with nothing in it
            return (
                <div>
                    <select defaultValue={"Value"} onChange={handleValue} >
                        <option value="Value" key="Value" disabled>
                            Value
                        </option>
                    </select>
                </div>
            );
        }
    }

    // These field types boil down to numeric values
    if ((selectedFieldType === COLUMN_TYPES['NUM']) ||
        (selectedFieldType === COLUMN_TYPES['HHMM']) ||
        (selectedFieldType === COLUMN_TYPES['TIME_TO_EXPIRATION']))
    {
        // Determine if this field has a time data type; this is used
        // for putting a "minutes" label next to the data input field
        const hasTime = (selectedFieldType === COLUMN_TYPES['HHMM']);

        // Single-value condition, e.g. "=" or ">"
        if (selectedCondition !== CONDITION_BETWEEN)
        {
            // Return a simple input field with an optional "minutes" label
            return (
                <div>
                    <input type="text" className="color-alert--value-field"
                        onChange={handleValue}
                        value={selectedValue || ""}
                    />
                    {hasTime &&
                    <>&nbsp;minutes from now</>}
                </div>
            );
        }
        // Dual value for BETWEEN
        else
        {
            // Return two input fields with an optional "minutes" label
            return (
                <div className="color-alert--between">
                    <input type="text" className="color-alert--value-field"
                        onChange={handleValue}
                        value={selectedValue || ""}
                    />
                    &nbsp;&lt;=&nbsp;value&nbsp;&lt;&nbsp;
                    <input type="text" className="color-alert--value-field"
                        onChange={handleValue2}
                        value={selectedValue2 || ""}
                    />
                    {hasTime &&
                    <>&nbsp;minutes from now</>}
                </div>
            );
        }
    }

    // If the field type is defined but not handled, report an error,
    // then go ahead and fall through to the default UI.
    if (selectedFieldType)
    {
        console.error("Unhandled field data type:", selectedFieldType);
    }

    // Undefined data type, such as when a user hasn't selected a field yet
    return (
        <div>
            <select defaultValue={selectedValue} onChange={handleValue} >
                <option value="Value" key="Value" disabled hidden>
                    Value
                </option>
            </select>
        </div>
    );
};

ColorAlertValue.propTypes = {
    // Data type of the currently selected field; passed from parent
    selectedFieldType: PropTypes.string,

    // The currently selected condition; passed from parent
    selectedCondition: PropTypes.string,

    // The currently selected value; passed from parent
    selectedValue: PropTypes.string,

    // Callback when a field value is selected/set; passed in from parent
    setSelectedValue: PropTypes.func.isRequired,

    // The currently selected second value of a "Between" condition; passed in
    // from parent
    selectedValue2: PropTypes.string,

    // Callback when a second value is set in a "Between" condition; passed in
    // from parent
    setSelectedValue2: PropTypes.func.isRequired,

    // The selected values of a "One Of" condition; passed in from parent
    selectedValueOneOf: PropTypes.arrayOf(PropTypes.string),

    // Callback when an enum condition is "One Of"; passed in from parent
    setSelectedValueOneOf: PropTypes.func.isRequired,

    // Index of this alert in the list of alerts; passed in from parent
    index: PropTypes.number.isRequired,
};

export default ColorAlertValue;
