import $ from "jquery";
import { DateTime } from "luxon";

import { COLUMN_TYPES } from "./ColumnTypes";
import { ALL_AIRPORT_CONFIG_ROLES } from "./Constants";
import { DEPARTURE_TABLE_ID,
         FLIGHT_MENU_ID } from "./TableConstants";
import * as columnColors from "../column_utils/columnColors";
import * as columnFormats from "../column_utils/columnFormats";

require('datatables.net-select-dt');

/**
 * This file contains an object that holds the various columns and their
 * associated information, as well as a few helper functions.
 */

/**
 * This object is a container for the various column types and their associated
 * information.
 * A column object contains the following properties:
 *          value: A name for identification, required by react-dual-list-box,
 *                 will always equal the column name
 *          title: The display value for this column, required by
 *                 datatables.net for columns
 *          label: The display value for this column, required by
 *                 react-dual-list-box, will always equal the title
 *           data: The name of the property that contains the data for this
 *                 column, required by datatables.net
 *            key: The unique identifier for the field.  This is largely
 *                 redundant of 'data' but is needed for the xxxCarrier1 and 2
 *                 fields which share the same xxxCarrier data element.
 *         render: optional; Used by datatables.net for display beyond a
 *                 simple string
 *           type: optional; used to identify column type for filtering or
 *                 sorting, value should be from COLUMN_TYPES
 *       template: optional; template for title and label if the value will be
 *                 filled in by user specific config value
 *        tooltip: optional; tooltip for column header to describe the column
 *    tipTemplate: optional; template for column header tooltip with user value
 *        visible: optional; default is true; set to false to not show a column
 * defaultContent: optional; default display value if the data isn't valid
 *     preprocess: optional; function that will be called on data between
 *                 retrieval from server and passing to the tables, takes as
 *                 parameters the full data object and the logged in user.
 *
 * Note1: Since the column objects are passed as is to other packages/libraries,
 *        be sure to check that property names are not in conflict with the
 *        packages/libraries the column objects may be passed to.
 * Note2: Since the "value" property is the same as the name, and the "label"
 *        property is the same as the "title", they are copied over en masse
 *        after all columns are defined.
 */
export const COLUMNS = Object.freeze({
    TOS_DT_COORDINATION_STATE_COLUMN: {
        title: "Coord State",
        data: "coordinationStatus",
        key: "coordinationStatus",
        type: COLUMN_TYPES.COORD_STATE,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatCoordinationState(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorCoordinationState(cellNode, cellData);
        },
    },
    TOS_DT_CPDLC_COLUMN: {
        title: "CPDLC",
        data: "cpdlcDcl",
        key: "cpdlcDcl",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-head-center dt-body-center",
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    TOS_DT_ELIGIBILITY_STATE_COLUMN: {
        title: "Eligibility State",
        data: "eligibilityStatus",
        key: "eligibilityStatus",
        type: COLUMN_TYPES.ELIGIBILITY_STATE,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatEligibilityState(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorEligibilityState(cellNode, cellData);
        },
    },
    TOS_DT_ESTIMATED_TAKE_OFF_TIME_COLUMN: {
        title: "ETOT",
        data: "estimatedTakeOffTime-luxon",
        key: "estimatedTakeOffTime",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorEtot(cellNode, cellData);
        },
        preprocess: makeDateConversionPreprocessor("estimatedTakeOffTime"),
    },
    TOS_DT_IN_DELAY_COLUMN: {
        title: "IN Delay",
        data: "inDelay",
        key: "inDelay",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorInDelay(cellNode, cellData);
        },
    },
    TOS_DT_NUM_TOS_CANDIDATE_COLUMN: {
        title: "Num TOS Cand",
        data: "numTosCandidates",
        key: "numTosCandidates",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
    },
    TOS_DT_NUM_TOS_SUBMITTED_COLUMN: {
        title: "Num TOS Sub",
        data: "numTosSubmitted",
        key: "numTosSubmitted",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
    },
    TOS_DT_OFF_DELAY_COLUMN: {
        title: "OFF Delay",
        data: "offDelay",
        key: "offDelay",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorOffDelay(cellNode, cellData);
        },
    },
    TOS_DT_SCRATCH_PAD_COLUMN: {
        title: "Scratch Pad",
        data: "scratchPad",
        key: "scratchPad",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatScratch(data, type, row, 20);
        },
    },
    TOS_DT_SWIM_STATUS_COLUMN: {
        title: "SWIM Status",
        data: "swimStatus",
        key: "swimStatus",
        type: COLUMN_TYPES.SWIM_STATE,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatSwimState(data, type, row);
        },
    },
    TOS_DT_TIME_TO_EXPIRATION_COLUMN: {
        title: "Time to Expiration",
        data: "timeToExpiration",
        key: "timeToExpiration",
        type: COLUMN_TYPES.TIME_TO_EXPIRATION,
        className: "dt-left",
        tipTemplate: "Countdown timer (in minutes) to the threshold prior to P-time (POBT).  Upon expiration at 0 min, flight Eligibility State changes to Expired.  Active in CENTER_TAG Center TOS Approval Mode only.",
        render: function(data, type, row){
            return columnFormats.formatTimeToExpiration(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTimeToExpiration(cellNode, cellData);
        },
    },
    TOS_DT_TMI_INFO_COLUMN: {
        title: "TMI Info",
        data: "tmiInfo",
        key: "tmiInfo",
        type: COLUMN_TYPES.STRING,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row) {
            return columnFormats.formatLongString(data, type, row, 12);
        }
    },
    TOS_DT_TOP_ADDITIONAL_NM_COLUMN: {
        title: "Top Add nm",
        data: "topAdditionalNm",
        key: "topAdditionalNm",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAdditionalNm(cellNode, cellData);
        },
    },
    TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN: {
        template: "Top Agg AIRPORT_TAG Del Sav",
        data: "topAggregateDelaySavingsAirport",
        key: "topAggregateDelaySavingsAirport",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavAirport(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save flights at the airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN: {
        template: "Top Agg CARRIER1_TAG Del Sav",
        data: "topAggregateDelaySavingsCarrier",
        key: "topAggregateDelaySavingsCarrier1",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavCarrier1(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures within the CARRIER1_TAG air carrier at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save the air carrier at this airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN: {
        template: "Top Agg CARRIER2_TAG Del Sav",
        data: "topAggregateDelaySavingsCarrier",
        key: "topAggregateDelaySavingsCarrier2",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavCarrier2(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures within the CARRIER2_TAG air carrier at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save the air carrier at this airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN: {
        template: "Top Agg FLEET_TAG Del Sav",
        data: "topAggregateDelaySavingsFleet",
        key: "topAggregateDelaySavingsFleet",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavFleet(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures within the FLEET_TAG at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save the fleet at this airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.",
    },
    TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN: {
        template: "Top Agg TRACON_TAG Del Sav",
        data: "topAggregateDelaySavingsTracon",
        key: "topAggregateDelaySavingsTracon",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavTracon(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures at all airports in TRACON_TAG. That is, rerouting this flight on the given route is predicted to save flights at all airports in the tracon a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.",
    },
    TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_AIRPORT_COLUMN: {
        template: "Top Avg AIRPORT_TAG Del Sav",
        data: "topAverageDelaySavingsAirport",
        key: "topAverageDelaySavingsAirport",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        tipTemplate: "On average, each departure scheduled to take off from AIRPORT_TAG within an hour of this flight is predicted to save X minutes.",
    },
    TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER1_COLUMN: {
        template: "Top Avg CARRIER1_TAG Del Sav",
        data: "topAverageDelaySavingsCarrier",
        key: "topAverageDelaySavingsCarrier1",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        tipTemplate: "On average, each departure in the CARRIER1_TAG air carrier which is scheduled to take off from AIRPORT_TAG within an hour of this flight is predicted to save X minutes."
    },
    TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER2_COLUMN: {
        template: "Top Avg CARRIER2_TAG Del Sav",
        data: "topAverageDelaySavingsCarrier",
        key: "topAverageDelaySavingsCarrier2",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        tipTemplate: "On average, each departure in the CARRIER2_TAG air carrier which is scheduled to take off from AIRPORT_TAG within an hour of this flight is predicted to save X minutes."
    },
    TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_FLEET_COLUMN: {
        template: "Top Avg FLEET_TAG Del Sav",
        data: "topAverageDelaySavingsFleet",
        key: "topAverageDelaySavingsFleet",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        tipTemplate: "On average, each departure in FLEET_TAG which is scheduled to take off from AIRPORT_TAG within an hour of this flight is predicted to save X minutes."
    },
    TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_TRACON_COLUMN: {
        template: "Top Avg TRACON_TAG Del Sav",
        data: "topAverageDelaySavingsTracon",
        key: "topAverageDelaySavingsTracon",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        tipTemplate: "On average, each departure scheduled to take off from all airports in TRACON_TAG within an hour of this flight is predicted to save X minutes.",
    },
    TOS_DT_TOP_ROUTE_ID_COLUMN: {
        title: "Top Alt Rte ID",
        data: "topRouteId",
        key: "topRouteId",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
    },
    TOS_DT_TOP_ROUTE_COLUMN: {
        title: "Top Alt Route",
        data: "topRoute",
        key: "topRoute",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
    },
    TOS_DT_TOP_DEPARTURE_FIX_COLUMN: {
        title: "Top Dep Fix",
        data: "topDepartureFix",
        key: "topDepartureFix",
        type: COLUMN_TYPES.DEPARTURE_FIX,
        className: "dt-left",
    },
    TOS_DT_TOP_DEPARTURE_GATE_COLUMN: {
        title: "Top Dep Gate",
        data: "topDepartureFixGate",
        key: "topDepartureFixGate",
        type: COLUMN_TYPES.DEPARTURE_GATE,
        className: "dt-left",
    },
    TOS_DT_TOP_ESTIMATED_TAKE_OFF_TIME_COLUMN: {
        title: "Top ETOT",
        data: "topEstimatedTakeOffTime-luxon",
        key: "topEstimatedTakeOffTime",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        preprocess: makeDateConversionPreprocessor("topEstimatedTakeOffTime"),
    },
    TOS_DT_TOP_IN_DELAY_COLUMN: {
        title: "Top IN Delay",
        data: "topInDelay",
        key: "topInDelay",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopInDelay(cellNode, cellData);
        },
    },
    TOS_DT_TOP_IN_DELAY_SAVINGS_COLUMN: {
        title: "Top IN Del Sav",
        data: "topInDelaySavings",
        key: "topInDelaySavings",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopInDelSav(cellNode, cellData);
        },
    },
    TOS_DT_TOP_OFF_DELAY_COLUMN: {
        title: "Top OFF Delay",
        data: "topOffDelay",
        key: "topOffDelay",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopOffDelay(cellNode, cellData);
        },
    },
    TOS_DT_TOP_OFF_DELAY_SAVINGS_COLUMN: {
        title: "Top OFF Del Sav",
        data: "topOffDelaySavings",
        key: "topOffDelaySavings",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopOffDelSav(cellNode, cellData);
        },
    },
    TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN: {
        title: "Top Prob Del Sav > RTC",
        data: "topProbabilityDelaySavingsRtc",
        key: "topProbabilityDelaySavingsRtc",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row, false, "%");
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopProbDelSavRtc(cellNode, cellData);
        },
    },
    TOS_DT_TOP_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN: {
        title: "Top Prob Del Sav > 0",
        data: "topProbabilityDelaySavingsZero",
        key: "topProbabilityDelaySavingsZero",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row, false, "%");
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopProbDelSavZero(cellNode, cellData);
        },
    },
    TOS_DT_TOP_RELATIVE_TRAJECTORY_COST_COLUMN: {
        title: "Top RTC",
        data: "topRelativeTrajectoryCost",
        key: "topRelativeTrajectoryCost",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopRtc(cellNode, cellData);
        },
    },
    TOS_DT_TOP_ROUTE_TYPE_COLUMN: {
        title: "Top Rte Type",
        data: "topSource",
        key: "topSource",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
    },
    TOS_DT_TOP_RTC_NUM_COLUMN: {
        title: "Top RTC #",
        data: "topSourceRtc",
        key: "topSourceRtc",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        defaultContent: "N/A",
    },
    TOS_DT_TOP_RUNWAY_COLUMN: {
        title: "Top Rwy",
        data: "topRunway",
        key: "topRunway",
        type: COLUMN_TYPES.RUNWAY,
        className: "dt-left",
        preprocess: function(data, user){
            if (data.topRunway && ALL_AIRPORT_CONFIG_ROLES.includes(user.role))
            {
                let origin = data.origin;
                if (origin && origin.length === 3)
                {
                    origin = "K" + data.origin
                }
                data.topRunway = origin + ":" + data.topRunway;
            }
        },
    },

    AIRCRAFT_TYPE_COLUMN: {
        title: "AC Type",
        data: "aircraftType",
        key: "aircraftType",
        type: COLUMN_TYPES.ALPHA_NUM,
        className: "dt-left",
    },
    AIRLINE_COLUMN: {
        title: "Airline",
        data: "airline",
        key: "airline",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
    },
    AMA_TAXI_DURATION_COLUMN: {
        title: "AMA Taxi Duration",
        data: "amaTaxiDuration",
        key: "amaTaxiDuration",
        type: COLUMN_TYPES.NUM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatSecondsHHMMSS(data, type, row, false);
        },
    },
    DEPARTURE_FIX_COLUMN: {
        title: "Dep Fix",
        data: "departureFix",
        key: "departureFix",
        type: COLUMN_TYPES.DEPARTURE_FIX,
        className: "dt-left",
        searchable: true,
    },
    DEPARTURE_GATE_COLUMN: {
        title: "Dep Gate",
        data: "departureGate",
        key: "departureGate",
        type: COLUMN_TYPES.DEPARTURE_GATE,
        className: "dt-left",
        searchable: true,
    },
    DESTINATION_COLUMN: {
        title: "Dest",
        data: "destination",
        key: "destination",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
        searchable: true,
    },
    EDCT_COLUMN: {
        title: "EDCT",
        data: "edct-luxon",
        key: "edct",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        preprocess: makeDateConversionPreprocessor("edct"),
    },
    EOBT_COLUMN: {
        title: "EOBT",
        data: "eobt-luxon",
        key: "eobt",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorEobt(cellNode, cellData);
        },
        preprocess: makeDateConversionPreprocessor("eobt"),
    },
    EQUIPMENT_COLUMN: {
      title: "Equipment",
      data: "equipment",
      key: "equipment",
      type: COLUMN_TYPES.STRING,
    },
    ALT_COLUMN: {
        title: "Altitude",
        data: "altitude",
        key: "altitude",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatAltitude(data, type, row);
        },
    },
    FILED_FLIGHT_COLUMN: {
        title: "Filed Flight",
        data: "filedFlight",
        key: "filedFlight",
        type: COLUMN_TYPES.BOOLEAN,
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    INITIAL_FLIGHT_COLUMN: {
        title: "Initial Flight",
        data: "initialFlight",
        key: "initialFlight",
        type: COLUMN_TYPES.BOOLEAN,
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    FLIGHT_ID_COLUMN: {
        title: "Flight ID",
        data: "acid",
        key: "acid",
        type: COLUMN_TYPES.ALPHA_NUM,
        className: "fm-trigger dt-body-nowrap dt-left",
        searchable: true,
    },
    FLIGHT_STATUS_COLUMN: {
        title: "Flight Status",
        data: "flightStatus",
        key: "flightStatus",
        type: COLUMN_TYPES.FLIGHT_STATUS,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatFlightStatus(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorFlightStatus(cellNode, cellData);
        },
    },
    GROUND_STOP_COLUMN: {
        title: "Ground Stop",
        data: "groundStop",
        key: "groundStop",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-center",
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    HAS_APREQ_COLUMN: {
        title: "Has APREQ",
        data: "hasApreq",
        key: "hasApreq",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-center",
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    HAS_EDCT_COLUMN: {
        title: "Has EDCT",
        data: "hasEdct",
        key: "hasEdct",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-center",
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    HAS_SCRATCH_PAD_COLUMN: {
        title: "Has Scratch Pad",
        data: "hasScratchPad",
        key: "hasScratchPad",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-center",
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    LOBT_COLUMN: {
        title: "LOBT",
        data: "lobt-luxon",
        key: "lobt",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        preprocess: makeDateConversionPreprocessor("lobt"),
    },
    LONG_ON_BOARD_COLUMN: {
        title: "Long On Board",
        data: "longOnBoard",
        key: "longOnBoard",
        type: COLUMN_TYPES.NUM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatSecondsHHMMSS(data, type, row, true);
        },
    },
    MAJOR_CARRIER_COLUMN: {
        title: "Major Carrier",
        data: "majorCarrier",
        key: "majorCarrier",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
    },
    MIT_COLUMN: {
        title: "MIT",
        data: "mit",
        key: "mit",
        type: COLUMN_TYPES.STRING,
        className: "dt-body-nowrap dt-left",
    },
    ORIGIN_COLUMN: {
        title: "Origin",
        data: "origin",
        key: "origin",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
        searchable: true,
    },
    PARKING_GATE_COLUMN: {
        title: "Parking Gate",
        data: "parkingGate",
        key: "parkingGate",
        type: COLUMN_TYPES.ALPHA_NUM,
        className: "dt-left",
    },
    POBT_COLUMN: {
        title: "POBT",
        data: "pobt-luxon",
        key: "pobt",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        preprocess: makeDateConversionPreprocessor("pobt"),
    },
    ROUTE_COLUMN: {
        title: "Route of Flight",
        data: "route",
        key: "route",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
        render: function(data, type, row) {
            return columnFormats.formatLongString(data, type, row, 20);
        }
    },
    RUNWAY_COLUMN: {
        title: "Rwy",
        data: "runway",
        key: "runway",
        type: COLUMN_TYPES.RUNWAY,
        className: "dt-left",
        preprocess: function(data, user){
            if (data.runway && ALL_AIRPORT_CONFIG_ROLES.includes(user.role))
            {
                let origin = data.origin;
                if (origin && origin.length === 3)
                {
                    origin = "K" + data.origin
                }
                data.runway = origin + ":" + data.runway;
            }
        },
    },
    RUNWAY_TIME_COLUMN: {
        title: "Rwy Time",
        data: "runwayTime-luxon",
        key: "runwayTime",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        preprocess: makeDateConversionPreprocessor("runwayTime"),
    },
    SOBT_COLUMN: {
        title: "SOBT",
        data: "sobt-luxon",
        key: "sobt",
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        preprocess: makeDateConversionPreprocessor("sobt"),
    },
    TAXI_TIME_COLUMN: {
        title: "Taxi Time",
        data: "taxiTime",
        key: "taxiTime",
        type: COLUMN_TYPES.NUM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatSecondsHHMMSS(data, type, row, false);
        },
    },
    INTERNATIONAL_COLUMN: {
        title: "Int’l",
        data: "international",
        key: "international",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-center",
        render: function(data, type, row){
            return columnFormats.formatBoolean(data, type, row);
        },
    },
    // TOS_FM_CURRENT_ROUTE is forced as 
    // the first column of flight menu for sorting, 
    // so intentionally not in ALL_FLIGHT_MENU_COLUMNS
    TOS_FM_CURRENT_ROUTE_COLUMN: {
        title: "Current",
        key: "isCurrentRoute",
        type: COLUMN_TYPES.BOOLEAN,
        render: function(data, type, row) {
            return row.isFiledRoute || row.isInitialRoute;
        },
        visible: false,
    },
    TOS_FM_FILED_ROUTE_COLUMN: {
        title: "Filed",
        data: "isFiledRoute",
        key: "isFiledRoute",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-left",
        visible: false,
    },
    TOS_FM_INITIAL_ROUTE_COLUMN: {
        title: "Initial",
        data: "isInitialRoute",
        key: "isInitialRoute",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-left",
        visible: false,
    },
    TOS_FM_TOP_ROUTE_COLUMN: {
        title: "Top",
        data: "isTopRoute",
        key: "isTopRoute",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-left",
        visible: false,
    },
    TOS_FM_ADDITIONAL_NM_COLUMN: {
        title: "Add nm",
        data: "additionalNm",
        key: "additionalNm",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAdditionalNm(cellNode, cellData);
        },
    },
    TOS_FM_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN: {
        template: "Agg AIRPORT_TAG Del Sav",
        data: "aggregateDelaySavingsAirport",
        key: "aggregateDelaySavingsAirport",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavAirport(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save flights at this airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_FM_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN: {
        template: "Agg TRACON_TAG Del Sav",
        data: "aggregateDelaySavingsTracon",
        key: "aggregateDelaySavingsTracon",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavTracon(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures at all airports in TRACON_TAG. That is, rerouting this flight on the given route is predicted to save flights at all airports in TRACON_TAG a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_FM_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN: {
        template: "Agg FLEET_TAG Del Sav",
        data: "aggregateDelaySavingsFleet",
        key: "aggregateDelaySavingsFleet",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavFleet(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures within the FLEET_TAG at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save the fleet at this airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_FM_AGGREGATE_DELAY_SAVINGS_CARRIER_COLUMN: {
        title: "Agg Carrier Del Sav",
        data: "aggregateDelaySavingsCarrier",
        key: "aggregateDelaySavingsCarrier",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopAggDelSavCarrier1(cellNode, cellData);
        },
        tipTemplate: "Sum of predicted Delay Savings (minutes) for the TOS flight and subsequent departures at AIRPORT_TAG. That is, rerouting this flight on the given route is predicted to save carrier flights at this airport a total of X minutes. (Applies to departures scheduled to takeoff within 1 hour of this flight.)",
    },
    TOS_FM_ALT_FOR_RTC_COLUMN: {
        title: "Alt 4 RTC",
        data: "altitude",
        key: "alt4Rtc",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatAltitude(data, type, row);
        },
    },
    TOS_FM_ROUTE_ID_COLUMN: {
        title: "Route ID",
        data: "id",
        key: "id",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
        render: function(data, type, row) {
            return data ? data : row.hasFiled ? "Filed" : "Initial";
        },
    },
    TOS_FM_COORDINATION_STATE_COLUMN: {
        title: "Coord State",
        data: "coordinationStatus",
        key: "coordinationStatus",
        type: COLUMN_TYPES.COORD_STATE,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatCoordinationState(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorCoordinationState(cellNode, cellData);
        },
    },
    TOS_FM_DEPARTURE_GATE_COLUMN: {
        title: "Dep Gate",
        data: "departureFixGate",
        key: "departureFixGate",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
    },
    TOS_FM_DISTANCE_NM_COLUMN: {
        title: "Dist nm",
        data: "distanceNm",
        key: "distanceNm",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: $.fn.dataTable.render.number('', '.', 0),
    },
    TOS_FM_ELIGIBILITY_STATE_COLUMN: {
        title: "Eligibility State",
        data: "eligibilityStatus",
        key: "eligibilityStatus",
        type: COLUMN_TYPES.ELIGIBILITY_STATE,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatEligibilityState(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorEligibilityState(cellNode, cellData);
        },
    },
    TOS_FM_ESTIMATED_TAKE_OFF_TIME_COLUMN: {
        title: "ETOT",
        data: 'estimatedTakeOffTime-luxon',
        key: 'estimatedTakeOffTime',
        type: COLUMN_TYPES.HHMM,
        className: "dt-left",
        render: function(data, type, row){
            return columnFormats.formatDateTimeHHMM(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorEtot(cellNode, cellData);
        },
        preprocess: makeDateConversionPreprocessor("estimatedTakeOffTime"),
    },
    TOS_FM_IN_DELAY_COLUMN: {
        title: "IN Delay",
        data: "inDelay",
        key: "inDelay",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, rowData,
                         unusedRow, unusedCol) {
            if (rowData.isFiledRoute || rowData.isInitialRoute)
            {
                return columnColors.colorInDelay(cellNode, cellData);
            }
            return columnColors.colorTopInDelay(cellNode, cellData);
        },
    },
    TOS_FM_IN_DELAY_SAVINGS_COLUMN: {
        title: "IN Del Sav",
        data: "inDelaySavings",
        key: "inDelaySavings",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopInDelSav(cellNode, cellData);
        },
    },
    TOS_FM_IN_SWIM_COLUMN: {
        title: "Included in TOS",
        data: "localInSwim",
        key: "inSwim",
        type: COLUMN_TYPES.BOOLEAN,
        className: "dt-center in-tos",
        render: function(data, type, row){
            return columnFormats.formatInSwimCheckbox(data, type, row);
        }
    },
    TOS_FM_OFF_DELAY_COLUMN: {
        title: "OFF Delay",
        data: "offDelay",
        key: "offDelay",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, rowData,
                         unusedRow, unusedCol) {
            if (rowData.isFiledRoute || rowData.isInitialRoute)
            {
                return columnColors.colorOffDelay(cellNode, cellData);
            }
            return columnColors.colorTopOffDelay(cellNode, cellData);
        },
    },
    TOS_FM_OFF_DELAY_SAVINGS_COLUMN: {
        title: "OFF Del Sav",
        data: "offDelaySavings",
        key: "offDelaySavings",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopOffDelSav(cellNode, cellData);
        },
    },
    TOS_FM_PROBABILITY_DELAY_SAVINGS_RTC_COLUMN: {
        title: "Prob Del Sav > RTC",
        data: "probabilityDelaySavingsRtc",
        key: "probabilityDelaySavingsRtc",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row, false, "%");
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopProbDelSavRtc(cellNode, cellData);
        },
    },
    TOS_FM_PROBABILITY_DELAY_SAVINGS_ZERO_COLUMN: {
        title: "Prob Del Sav > 0",
        data: "probabilityDelaySavingsZero",
        key: "probabilityDelaySavingsZero",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberOneDecimal(data, type, row, false, "%");
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopProbDelSavZero(cellNode, cellData);
        },
    },
    TOS_FM_RELATIVE_TRAJECTORY_COST_COLUMN: {
        title: "RTC",
        data: "relativeTrajectoryCost",
        key: "relativeTrajectoryCost",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        render: function(data, type, row){
            return columnFormats.formatNumberWithSign(data, type, row);
        },
        createdCell: function(cellNode, cellData, unusedRowData,
                         unusedRow, unusedCol) {
            return columnColors.colorTopRtc(cellNode, cellData);
        },
    },
    TOS_FM_ROUTE_COLUMN: {
        title: "Route",
        data: "route",
        key: "route",
        type: COLUMN_TYPES.STRING,
        className: "dt-left",
        render: function(data, type, row) {
            return columnFormats.formatLongString(data, type, row, 40);
        }
    },
    TOS_FM_ROUTE_TYPE_COLUMN: {
        title: "Rte Type",
        data: "source",
        key: "source",
        type: COLUMN_TYPES.STRING,
        className: "dt-right",
    },
    TOS_FM_RTC_NUM_COLUMN: {
        title: "RTC #",
        data: "sourceRtc",
        key: "sourceRtc",
        type: COLUMN_TYPES.NUM,
        className: "dt-right",
        defaultContent: "N/A",
    },
    TOS_FM_RUNWAY_COLUMN: {
        title: "Rwy",
        data: "runway",
        key: "runway",
        type: COLUMN_TYPES.RUNWAY,
        className: "dt-left",
    },
    TOS_FM_SWIM_STATUS_COLUMN: {
        title: "SWIM Status",
        data: "swimStatus",
        key: "swimStatus",
        type: COLUMN_TYPES.SWIM_STATE,
        className: "dt-body-nowrap dt-left",
        render: function(data, type, row){
            return columnFormats.formatSwimState(data, type, row);
        },
    },
    TOS_FM_VALID_ROUTE_COLUMN: {
        title: "Valid Rte",
        data: "isValid",
        key: "isValid",
        type: COLUMN_TYPES.BOOLEAN,
    }
});

/**
 * Makes a function to convert a data string into a luxon DateTime.
 *
 * @param {string} fieldName  provides the field to convert
 *
 * @return function to add field with "-luxon" appended containing DateTime
 *         value
 */
function makeDateConversionPreprocessor(fieldName)
{
    return function(data){
        if (data[fieldName])
        {
            data[fieldName + "-luxon"] =
                DateTime.fromISO(data[fieldName]).toUTC();
        }
        else if (data[fieldName + "-luxon"])
        {
            data[fieldName + "-luxon"] = null;
        }
    };
}


const preprocessMethods = {};
preprocessMethods[FLIGHT_MENU_ID] = [];
preprocessMethods[DEPARTURE_TABLE_ID] = [];

// Copy in the properties that are needed for the column selection
for (const column in COLUMNS )
{
    COLUMNS[column].value = column;
    if (COLUMNS[column].template && !COLUMNS[column].title)
    {
        COLUMNS[column].title = COLUMNS[column].template;
    }
    COLUMNS[column].label = COLUMNS[column].title;
    COLUMNS[column].name = COLUMNS[column].title;
    if (COLUMNS[column].preprocess)
    {
        if (column.startsWith("TOS_FM"))
        {
            preprocessMethods[FLIGHT_MENU_ID].push(COLUMNS[column].preprocess);
        }
        else
        {
            preprocessMethods[DEPARTURE_TABLE_ID].push(COLUMNS[column].preprocess);
        }
    }
}

/**
 * Returns the column object that contains the given column value.
 *
 * @param {string} searchValue  The value property to search for.
 *
 * @return {COLUMNS.object} The first column object found with a matching
 *                          value property.
 */
export function getColumnByValue(searchValue) {
    return COLUMNS[searchValue];
}

/**
 * Returns the column object that contains the given column label.
 *
 * @param {string} searchlabel  The label property to search for.
 *
 * @return {COLUMNS.object} The first column object found with a matching
 *                          label property.
 */
export function getColumnByLabel(searchLabel) {
    for (const value of Object.values(COLUMNS))
    {
        if (value.label === searchLabel)
        {
            return value;
        }
    }

    return undefined;
}

/**
 * Returns the list of preprocessing methods extracted from the columns.
 *
 * @param {string} type  to indicate flight menu or departure table (e.g.
 *                       DEPARTURE_TABLE_ID or FLIGHT_MENU_ID)
 */
export function getPreprocessColumnMethods(type) {
    return preprocessMethods[type];
}

/**
 * Updates the templated column titles and tooltips with user configuration
 * values.
 *
 * @param {object} userValues                  user configuration values
 * @param {string} [userValues.airport]        airport name
 * @param {string} [userValues.carrier1]       user main carrier code
 * @param {string} [userValues.carrier1Title]  user main carrier text name
 * @param {string} [userValues.carrier2]       user sub carrier code
 * @param {string} [userValues.carrier2Title]  user sub carrier text name
 * @param {string} [userValues.fleet]          user major carrier code
 * @param {string} [userValues.fleetTitle]     user major carrier text name
 * @param {string} [userValues.tracon]         user tracon name
 * @param {string} [userValues.center]         user center name
 * @param {string} [userValues.tfix1]          first transition fix
 * @param {string} [userValues.tfix2]          second transition fix
 */
export function updateUserColumns(userValues)
{
    resetTemplates();
    updateAirportFields(userValues.airport);
    updateCarrier1Fields(userValues.carrier1, userValues.carrier1Title);
    updateCarrier2Fields(userValues.carrier2, userValues.carrier2Title);
    updateFleetFields(userValues.fleet, userValues.fleetTitle);
    updateTraconFields(userValues.tracon);
    updateCenterFields(userValues.center);
}

/**
 * Resets columns with template title or tooltip back to template for updating,
 * in case we're logging in again and have already replaced tags from the
 * templates.
 */
function resetTemplates()
{
    for (const colName in COLUMNS )
    {
        let col = COLUMNS[colName];
        if (col.template)
        {
            col.title = col.template;
            col.label = col.template;
            col.name = col.template;
        }

        if (col.tipTemplate)
        {
            col.tooltip = col.tipTemplate;
        }
    }
}

/**
 * Replaces tag values in the title, label, and tooltip for a column.
 *
 * @param {object} column   column to update
 * @param {string} tag      tag to replace
 * @param {string} newText  text to replace with
 */
function replaceDisplayText(column, tag, newText)
{
    if (column.template)
    {
        let after = column.title.replace(tag, newText);
        column.title = after;
        column.label = after;
        column.name = after;
    }

    if (column.tipTemplate)
    {
        let after = column.tooltip.replace(tag, newText);
        column.tooltip = after;
    }
}

/**
 * Sets up the data function for a column to only return a value if the
 * given field matches the given value (eg airline === "AAL").
 *
 * @param {object} column      column to modify
 * @param {string} checkField  name of field to check
 * @param {string} checkValue  value to compare with
 */
function filterData(column, checkField, checkValue)
{
    // if data is still a field name, save that into dataField.
    if (!(column.data instanceof Function))
    {
        column.dataField = column.data;
    }

    // use the standard dataTables.net data function instead just a field name
    column.data = function(row, unusedType, unusedSet, unusedMeta) {
        if (row[checkField] === checkValue)
        {
            return row[column.dataField];
        }
        else
        {
            return;
        }
    };
}

/**
 * Updates the columns that need airport tags replaced with the user airport.
 * Disables airport tag columns if userAirport is not set.
 *
 * @param {string} [userAirport]  name of user airport
 */
function updateAirportFields(userAirport)
{
    const airportFields = [
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN,
        COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_AIRPORT_COLUMN,
        COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_AIRPORT_COLUMN
    ];

    if (userAirport)
    {
        airportFields.forEach((field) => {
            replaceDisplayText(field, "AIRPORT_TAG", userAirport);
            field["disabled"] = false;
        });

        const airportTips = [
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER1_COLUMN,
            COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_CARRIER_COLUMN,
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER2_COLUMN,
            COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
            COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_FLEET_COLUMN,
            COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN
        ]

        // These fields have the airport in the tool tip
        airportTips.forEach((field) => {
            replaceDisplayText(field, "AIRPORT_TAG", userAirport);
        });
    }
    else
    {
        airportFields.forEach((field) => {
            field["disabled"] = true;
        });
    }
}

/**
 * Updates the columns that need Carrier1 tag replaced with user carrier1.
 * If userCarrierTitle is not set, the userCarrier1 value will be used. Disables
 * carrier1 tag columns if userCarrier1 is not set.
 *
 * @param {string} [userCarrier1]      main carrier code
 * @param {string} [userCarrierTitle]  text to use in the column title
 */
function updateCarrier1Fields(userCarrier1, userCarrierTitle)
{
    const carrier1Fields = [
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER1_COLUMN,
        COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER1_COLUMN,
    ];

    if (userCarrier1)
    {
        carrier1Fields.forEach((field) => {
            replaceDisplayText(field, "CARRIER1_TAG",
                userCarrierTitle ? userCarrierTitle : userCarrier1);
            filterData(field, "airline", userCarrier1);
            field["disabled"] = false;
        });
    }
    else
    {
        carrier1Fields.forEach((field) => {
            field["disabled"] = true;
        });
    }
}

/**
 * Updates columns that need to replace the carrier2 tag with user value.
 * If userCarrierTitle is not set, userCarrier2 value will be used. Disables
 * carrier2 tag columns if the userCarrier2 is not set.
 *
 * @param {string} [userCarrier2]      carrier code
 * @param {string} [userCarrierTitle]  text to replace tag in title
 */
function updateCarrier2Fields(userCarrier2, userCarrierTitle)
{
    const carrier2Fields = [
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_CARRIER2_COLUMN,
        COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_CARRIER2_COLUMN,
    ];

    if (userCarrier2)
    {
        carrier2Fields.forEach((field) => {
            replaceDisplayText(field, "CARRIER2_TAG",
                userCarrierTitle ? userCarrierTitle : userCarrier2);
            filterData(field, "airline", userCarrier2);
            field["disabled"] = false;
        });
    }
    else
    {
        carrier2Fields.forEach((field) => {
            field["disabled"] = true;
        });
    }
}

/**
 * Update the fleet tag with the user fleet value. If userFleetTitle is not
 * set, the userFleet value will be used. Disables fleet tag columns if the
 * user fleet is not set.
 *
 * @param {string} [userFleet]       main line carrier value
 * @param {string} [userFleetTitle]  text to replace the tag in the titles
 */
function updateFleetFields(userFleet, userFleetTitle)
{
    const fleetFields = [
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
        COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_FLEET_COLUMN,
        COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_FLEET_COLUMN,
    ];

    if (userFleet)
    {
        fleetFields.forEach((field) => {
            replaceDisplayText(field, "FLEET_TAG",
                userFleetTitle ? userFleetTitle : userFleet);
            field["disabled"] = false;
        });
    }
    else
    {
        fleetFields.forEach((field) => {
            field["disabled"] = true;
        });
    }
}

/**
 * Updates columns with tracon tags with the user tracon value. Disables tracon
 * tag columns if no tracon is set.
 *
 * @param {string} [userTracon]  tracon name
 */
function updateTraconFields(userTracon)
{
    const traconFields = [
        COLUMNS.TOS_DT_TOP_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN,
        COLUMNS.TOS_DT_TOP_AVERAGE_DELAY_SAVINGS_TRACON_COLUMN,
        COLUMNS.TOS_FM_AGGREGATE_DELAY_SAVINGS_TRACON_COLUMN,
    ];

    if (userTracon)
    {
        traconFields.forEach((field) => {
            replaceDisplayText(field, "TRACON_TAG", userTracon);
            field["disabled"] = false;
        });
    }
    else
    {
        traconFields.forEach((field) => {
            field["disabled"] = true;
        });
    }
}

/**
 * Updates fields with the center value, currently just one field with tooltip
 * to update.
 *
 * @param {string} [userCenter]  center name defined for user
 */
function updateCenterFields(userCenter)
{
    if (userCenter)
    {
        replaceDisplayText(COLUMNS.TOS_DT_TIME_TO_EXPIRATION_COLUMN,
            "CENTER_TAG", userCenter);
    }
}
