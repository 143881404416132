import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { DateTime } from "luxon";
import $ from "jquery";

import { isCancel } from "axios";

import "./css/tables.css";

import { addMessage,
         addTable,
         closeErrorModal,
         openErrorModal,
         startTableUpdates,
         updateAlertFlights,
         updateColumns,
         updateDataTimestamp,
         updateDepartures,
         updateFlightRoutes,
         updateTosStatus,
         updateRefreshInterval} from "./actions";
import { setColorAlertFieldsForLoggedInUser } from "./constants/ColorAlertConstants";
import { getPreprocessColumnMethods,
         updateUserColumns } from "./constants/ColumnField";
import { ACKED_ALERTS,
         ALERT_TYPE_COORDINATION,
         ALERT_TYPE_FILTER,
         ALERT_TYPE_SCRATCH_PAD,
         ALERT_TYPE_CANDIDATE,
         APP_NAME,
         TIME_CURRENT,
         TIME_FIXED,
         UPDATE_INTERVAL } from "./constants/Constants";        
import { COLUMN_TYPES } from "./constants/ColumnTypes";
import { ALERT_FILTER_BUTTON,
         ALERT_FILTER_TABLE_ID,
         CANDIDATE_FILTER_BUTTON,
         CANDIDATE_FILTER_TABLE_ID,
         DEPARTURE_TABLE_ID,
         FLIGHT_MENU_ID } from "./constants/TableConstants";
import FlightTable from "./components/table/FlightTable";
import AlertTable from "./components/table/AlertTable";
import ShowMeTable from "./components/table/ShowMeTable";
import { addConditions,
         addConfigurationConditions,
         modifyBaseConditions } from "./column_utils/columnConditions";
import { addDefaultOrdering, addOrdering } from "./column_utils/columnSorting";
import CandidateAlertModal from "./components/modals/CandidateAlertModal";
import FlightAlertModal from "./components/modals/FlightAlertModal";
import MsgModal from "./components/modals/MsgModal";
import AddTableButton from "./components/table/AddTableButton";
import userConfig from "./config/user_config.json";
import { makeCoordinationAlertFilter, 
         makeFlightAlertFilter,
         getFilterReason,
         makeScratchPadAlertFilter,
         makeCandidateAlertFilter } from "./utils/tableUtils";
import { tosClient } from "./utils/tosClient";

/**
 * Serves as a manager for the various tables. Also manages the data.
 */
class TablesManager extends Component
{
    static propTypes = {
        // Used to trigger a refresh of the update time and connection error;
        // passed in from parent
        onUpdate: PropTypes.func.isRequired,

        // Associative array object of flight menu gufi array per table; from 
        // redux
        flightMenus: PropTypes.object.isRequired,

        // Currently logged in user, from redux
        user: PropTypes.object.isRequired,

        // Flag if all tables have completed their data refresh; computed
        allUpdated: PropTypes.bool,

        // Data timestamp; from redux
        dataTimestamp: PropTypes.object.isRequired,

        // Time mode (current or historical); from redux
        timeMode: PropTypes.string.isRequired,

        // Object containing approver type for each airport; from redux
        tosApprovers: PropTypes.object,

        // Action creator to display a message in a  dialog; from redux
        addMessage: PropTypes.func.isRequired,

        // Action creator to close the error modal; from redux
        closeErrorModal: PropTypes.func.isRequired,

        // Action creator to open the error modal; from redux
        openErrorModal: PropTypes.func.isRequired,

        // Action creator to indicate table data refresh has started; from redux
        startTableUpdates: PropTypes.func.isRequired,

        // Action creator to update the flights to include in an alert; 
        // from redux
        updateAlertFlights: PropTypes.func.isRequired,

        // Action creator to update the columns definitions; from redux
        updateColumns: PropTypes.func.isRequired,

        // Action creator to update the data timestamp; from redux
        updateDataTimestamp: PropTypes.func.isRequired,

        // Function to update TOS status in redux
        updateTosStatus: PropTypes.func.isRequired,

        // Function to update TOS departure records in redux
        updateDepartures: PropTypes.func.isRequired,

        // function to update TOS flight menu records in redux
        updateFlightRoutes: PropTypes.func.isRequired,

        // function to update refresh interval in redux
        updateRefreshInterval: PropTypes.func.isRequired,

        // function to add a new table in redux
        addTable: PropTypes.func.isRequired,

        // List of table groups to display; from redux
        tableGroups: PropTypes.arrayOf(PropTypes.string).isRequired,

        // Indicator for settings still loading from server; computed from
        // flags from redux
        isLoadingSettings: PropTypes.bool,

        // Indicator if alert settings are in the process of changing;
        // from redux
        isAlertModalOpen: PropTypes.bool.isRequired,

        // Indicator if the error modal is open; from redux
        isErrorModalOpen: PropTypes.bool.isRequired,

        isIntervalUpdateNeeded: PropTypes.bool.isRequired,

        // List of flights currently being alerted; from Redux
        alertFlights: PropTypes.arrayOf(
            PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))).isRequired,

        // Whether coordination alerts are turned on; from redux
        alertCoordination: PropTypes.bool,

        // Whether flight filter alerts are turned on; from redux
        alertFiltered: PropTypes.bool,

        // Whether scratch pad alerts are turned on; from redux
        alertScratchPad: PropTypes.bool,

        // Whether candidate alerts are turned on; from redux
        alertCandidate: PropTypes.bool,
    };

    /**
     * Constructs the TablesManager class. This is used to manage the data
     * connection and control the rendering of the table groups.
     *
     * @param {*}    props
     * @param {func} props.onUpdate   function to call to post update status
     */
    constructor(props)
    {
        super(props);

        // Set the columns based on the user.  The color alert fields
        // depend on the user columns being initialized first.
        updateUserColumns(userConfig[this.props.user.roleUser]);
        setColorAlertFieldsForLoggedInUser();

        // @NPM comment this out when testing via "npm start"
        this.getConfiguration();

        // Set up the filters to find the flights for the Flight Alerts
        this.coordinationAlertFilter = makeCoordinationAlertFilter(
            this.props.user.role, userConfig[this.props.user.roleUser]);
        this.flightAlertFilter = makeFlightAlertFilter(
            this.props.user.role, userConfig[this.props.user.roleUser]);
        this.scratchPadAlertFilter = makeScratchPadAlertFilter(
            this.props.user.roleUser, userConfig[this.props.user.roleUser]);
        this.candidateAlertFilter = makeCandidateAlertFilter(
            this.props.user.role, userConfig[this.props.user.roleUser]);
        this.ackedFlights = [];

        this.departurePreprocessors = 
            getPreprocessColumnMethods(DEPARTURE_TABLE_ID);
        this.flightMenuPreprocessors = 
            getPreprocessColumnMethods(FLIGHT_MENU_ID);

        this.state =
        {
            isDataSetEmpty: true,
        };

        // make sure we have at least one table
        if (!this.props.tableGroups.length)
        {
            this.props.addTable(DEPARTURE_TABLE_ID + "_0");
        }
        this.scrollTop = 0;
    }

    /**
     * When the page gets sucessfully made, get the data and start the data
     * update interval.
     */
    componentDidMount()
    {
        // Initialize any custom sort ordering and searchBuilder conditions
        addDefaultOrdering(COLUMN_TYPES.NON_EMPTY_STRING);
        addDefaultOrdering(COLUMN_TYPES.RUNWAY);
        
        addOrdering();
        addConditions();
        modifyBaseConditions();

        // If the logout button is pressed, componentWillUnmount doesn't always 
        // get called on the redirect, so watch for it to stop the update.
        window.addEventListener("beforeunload", this.stopUpdating);

        // Restore saved ack list so we don't get alerted again when changing
        // the columns or updating settings, etc.
        const storageAckedFlights = localStorage.getItem(
            this.props.user.username + ":" + APP_NAME + ACKED_ALERTS);
        if (storageAckedFlights && storageAckedFlights.length)
        {
            this.ackedFlights = JSON.parse(storageAckedFlights);
        }

        // Update the data and then set a timer
        if (this.props.timeMode === TIME_CURRENT)
        {
            this.startUpdating();
        }
    }

    /**
     * If this component did update, then make the appropriate changes to
     * the table: update table data, update columns, clear out cached flight
     * menus, update the filter label.
     *
     * @param {object} prevProps The props object before the update
     */
    componentDidUpdate(prevProps)
    {
        if (!prevProps.allUpdated && this.props.allUpdated)
        {
            $(".main-frame").scrollTop(this.scrollTop);
        }

        if (this.props.isIntervalUpdateNeeded)
        {
            if (this.props.timeMode === TIME_CURRENT)
            {
                this.startUpdating();
            }
            else
            {
                this.stopUpdating();

                // Should only update 'Fixed' data if the timestamp is selected from the 
                // historical run time dropdown (not from the last 'current' refresh)
                if (this.props.timeMode === TIME_FIXED &&
                    (this.props.dataTimestamp.toMillis() < (Date.now() - UPDATE_INTERVAL)))
                {
                    this.updateData();
                }
            }

            // Don't need to update the refresh interval again until the next time mode change
            this.props.updateRefreshInterval(false)
        }
    }

    /**
     * Before the page is destroyed, removes the data update interval.
     */
    componentWillUnmount()
    {
        this.stopUpdating();
        window.removeEventListener("beforeunload", this.stopUpdating);
    }

    /**
     * Starts the repeating updating of data.
     */
    startUpdating()
    {
        this.updateData();
        this.interval = setInterval(this.updateData, UPDATE_INTERVAL);
    }

    /**
     * Stops the repeating updating of data.
     */
    stopUpdating()
    {
        if (this.interval)
        {
            clearInterval(this.interval);
        }
    }

    /**
     * Gets the airport configuration to supply configuration limited field
     * selections, such as runways and fixes, in filters.
     */
    getConfiguration()
    {
        let airport = this.props.user.location;

        // If the airport has a colon the format is "<tracon>:<airport1>,<airport2>,<etc.>"
        if( airport.includes( ":" ) ) {
            airport = airport.split( ":" )[1];
        }

        tosClient.getConfiguration(airport)
        .then((data) => {
            addConfigurationConditions(data);
            return true;
        })
        .catch((errMsg) => {
            if (isCancel(errMsg)) {
                console.log(errMsg.message);

                // If the request was cancelled due to the unavailability of the auth token,
                // Retry it after a 500ms delay.
                if (errMsg?.message?.includes(tosClient.ERROR_MSG_AUTH_TOKEN_UNAVAILABLE)) {
                    setTimeout(() => this.getConfiguration(), 500);
                }

                return;
            }

            this.props.onUpdate(false, errMsg);
            return false;
        });
    }

    /**
     * Called at a predefined interval to update the data. If update is
     * sucessful the data will be stored and update time refreshed.
     * Otherwise the error is logged.
     */
    updateData = async () =>
    {
        // Don't update when settings are loading
        if (this.props.isLoadingSettings)
        {
            console.log("Skip updating; settings are loading");
            return;
        }

        try
        {
            /*********************
             * UPDATE TOS STATUS
             *********************/
            let tosStatus;

            if (this.props.timeMode === TIME_CURRENT)
            {
                tosStatus = await tosClient.getTosStatus();

                // @NPM comment out the above and use this instead when 
                // testing via "npm start"
                //tosStatus = { approvalTypes: [] };
                //tosStatus.approvalTypes.push( 
                //     {airport: "DFW", approvalType: "TOWER", active:true});
            }
            else
            {
                // If running a query, warn the user so they know something is
                // happening.
                this.props.addMessage("Calling database for data at " +
                    this.props.dataTimestamp.toFormat("HH:mm:ss"));
                tosStatus = await 
                    tosClient.getTosStatus(this.props.dataTimestamp.toMillis());
            }
            this.props.updateTosStatus(tosStatus);

            /*********************
             * UPDATE FLIGHT ROUTES
             *********************/

            // Request flight route data first to reduce updates after the
            // departure table has updated
            if (this.props.flightMenus)
            {
                // Put the gufis into a set instead of an array to guarantee
                // a gufi is only included once
                let flatGufiSet = new Set();
                for (const gufiListTable in this.props.flightMenus)
                {
                    if (this.props.flightMenus[gufiListTable] &&
                        this.props.flightMenus[gufiListTable].length)
                    {
                        this.props.flightMenus[gufiListTable].forEach((gufi) => {
                            flatGufiSet.add(gufi);
                        });
                    }
                }
                if (flatGufiSet.size)
                {
                    let flightOptions = {
                        role: this.props.user.role,
                        carrier: userConfig[this.props.user.roleUser].carrierForRequests,
                        gufiList: [ ...flatGufiSet ],
                    }
                    if (this.props.timeMode !== TIME_CURRENT)
                    {
                        flightOptions.timestamp = 
                            this.props.dataTimestamp.toMillis();
                    }

                    let flightRouteData = await tosClient.getFlightRoutes(
                        flightOptions);
                    console.log("Retrieved " +
                        (flightRouteData ? flightRouteData.length : 0) +
                        " flight routes");
                    this.props.updateFlightRoutes(
                        this.processFlightRoutes(flightRouteData));
                }
            }

            /*********************
             * UPDATE DEPARTURES 
             *********************/

            let depOptions = {
                role: this.props.user.role,
                carrier: userConfig[this.props.user.roleUser].carrierForRequests
            }           
                 
            if (this.props.user.location)
            {
                if (this.props.user.location.includes(":"))
                {
                    depOptions.airports = this.props.user.location.split(":")[1].split(",");
                }
                else
                {
                    depOptions.airports = [this.props.user.location];
                }
            }
            if (this.props.timeMode !== TIME_CURRENT)
            {
                depOptions.timestamp = this.props.dataTimestamp.toMillis();
            }

            let departures = await tosClient.getDepartureAll(depOptions);
            // @NPM comment out the above and use this instead when 
            // testing via "npm start"
            //let departures = [];
            console.log("Retrieved " + departures.length + 
                 " departure flights");
            const processedDepartures = this.processDepartures(departures);

            this.scrollTop = $(".main-frame").scrollTop();
            this.props.startTableUpdates();
            this.props.updateDepartures(processedDepartures);

            // Update the PageHeader connection message
            this.props.onUpdate(true);
            if (this.props.timeMode === TIME_CURRENT)
            {
                this.props.updateDataTimestamp(DateTime.utc());
            }

            // Close the error modal if it is still open
            if (this.props.isErrorModalOpen)
            {
                this.props.closeErrorModal();
            }
        }
        catch (error)
        {
            if (isCancel(error)) {
                console.log(error.message);
                return;
            }

            console.error("Connection failed: " + error);
            // @NPM comment this out when testing via "npm start"
            this.props.openErrorModal("Connection Error", error);

            if (this.props.timeMode !== TIME_CURRENT)
            {
                // Clear the db loading message
                this.props.addMessage("");
            }

            // Update the PageHeader connection message
            this.props.onUpdate(false, error);
        }
    }

    /**
     * Processes the departure array from the server before passing it on
     * to the dataTables.
     *
     * @param {object[]} departureList  array of departure records from server
     *
     * @return possibly modified array of departure records from the server
     */
    processDepartures = (departureList) => {
        let statusDepartures = [];
        const [checkAlert, checkAirports] = this.getAlertChecks();

        departureList.forEach((depRecord) => {
        
            // Add flag to indicate that scratchpad is populated for filtering
            if ( depRecord.scratchPad && depRecord.scratchPad.length > 0 )
            {
                depRecord.hasScratchPad = true
            }
        
            // Don't update the alert list when alert configuration is being
            // updated, it gets messy.
            if (!this.props.isAlertModalOpen)
            {
                // Coordination and scratch filters are conditional;
                // flight filter alert can happen any time
                let passCoordFilter = false;
                let passScratchFilter = false;
                const passFlightFilter = this.props.alertFiltered &&
                    this.flightAlertFilter(depRecord);
                const passCandFilter = this.props.alertCandidate &&
                    this.candidateAlertFilter(depRecord);

                if (checkAlert)
                {
                    passCoordFilter = this.props.alertCoordination && 
                        this.coordinationAlertFilter(depRecord);
                    passScratchFilter = this.props.alertScratchPad &&
                        this.scratchPadAlertFilter(depRecord);
                }

                if (passCoordFilter || passFlightFilter || passScratchFilter ||
                    passCandFilter)
                {
                    if (!checkAirports || 
                         checkAirports.includes(depRecord.origin))
                    {
                        if (passCoordFilter)
                        {
                            statusDepartures.push({
                                acid: depRecord.acid,
                                type: ALERT_TYPE_COORDINATION,
                                coordinationStatus: 
                                    depRecord.coordinationStatus,
                                gufi: depRecord.gufi,
                            });
                        }
                        if (passFlightFilter)
                        {
                            statusDepartures.push({
                                acid: depRecord.acid,
                                type: ALERT_TYPE_FILTER,
                                gufi: depRecord.gufi,
                            });
                        }
                        if (passScratchFilter)
                        {
                            statusDepartures.push({
                                acid: depRecord.acid,
                                type: ALERT_TYPE_SCRATCH_PAD,
                                gufi: depRecord.gufi,
                            });
                        }
                        if (passCandFilter)
                        {
                            statusDepartures.push({
                                acid: depRecord.acid,
                                type: ALERT_TYPE_CANDIDATE,
                                gufi: depRecord.gufi,
                                info: {
                                  dest: depRecord.destination,
                                  eobt: depRecord.eobt,
                                  topOff: depRecord.topOffDelaySavings,
                                  off: depRecord.offDelay,
                                  topIn: depRecord.topInDelay,
                                  in: depRecord.inDelay,
                                }
                            });
                        }
                    }
                }
            }

            // any custom input processing by field
            this.departurePreprocessors.forEach((func) => {
                func(depRecord, this.props.user);
            });
        });
        this.checkStatusAlerts(statusDepartures);

        return departureList;
    };

    /**
     * Processes the flight route array from the server before passing it on
     * to the dataTables.
     *
     * @param {object[]} routesList  array of flight route records from server
     *
     * @return possibly modified array of flight route records from the server
     */
    processFlightRoutes = (routesList) => {
        if (this.flightMenuPreprocessors.length > 0)
        {
            routesList.forEach((routeRecord) => {
                this.flightMenuPreprocessors.forEach((func) => {
                    func(routeRecord, this.props.user);
                });
            });
        }

        return routesList;
    };

    /**
     * Pulls together what we need to determine if the user qualifies for alerts
     * based on role and current TOS approvers.
     *
     * @return [ boolean, array ] whether an alert is possible, and optional
     *                             array of airports to alert, if limited
     */
    getAlertChecks = () => {
        let checkAlert = false;
        let checkAirports;
        let user = this.props.user;

        // no alerts for historical data
        if (this.props.timeMode === TIME_CURRENT)
        {
            if (user.role === "ATC_TOWER")
            {
                checkAlert =
                    (this.props.tosApprovers[user.location] === "TOWER");
            }
            else if (user.role === "ATC_CENTER")
            {
                checkAirports = [];
                for (const [airport, approver] of 
                        Object.entries(this.props.tosApprovers))
                {
                    if (approver === "CENTER")
                    {
                        checkAirports.push(airport);
                    }
                }
                checkAlert = (checkAirports.length > 0);
            }
            else if (user.role !== "ATC_TRACON")
            {
                checkAlert = true;
            }
        }

        return [ checkAlert, checkAirports ];
    };

    /**
     * Checks a list of flights in alertable status. Gets the list of flights 
     * to alert that have not already been acknowledged for a given alert type.
     * Also clears the acknowledged list of any flights that no longer qualify 
     * for alerts.
     *
     * @param statusDepartures  acids of flights with currently-alertable 
     *                          status, which includes flights that may have 
     *                          already been alerted
     */
    checkStatusAlerts = (statusDepartures) => {
        // When loading the page, the first table updates don't have the filter
        // table for operators, so we have nothing qualifying but we don't
        // want to clear acked flights
        if (statusDepartures && (statusDepartures.length > 0))
        {
            // Of the already-acknowledged flights, only keep those that are 
            // still in the right status for alerting
            let ackedFlightsToKeep = this.ackedFlights.filter((acked) => {
                return statusDepartures.some(dep => 
                    ((dep.acid === acked.acid) &&
                     (dep.type === acked.type)));
            });

            // Of the flights that currently have an alertable state, only 
            // keep those that are have not already been acknowledged
            let newAlertDepartures = statusDepartures.filter((dep) => {
                return this.ackedFlights.every(acked => 
                    ((acked.acid !== dep.acid) ||
                    ((acked.acid === dep.acid) && (acked.type !== dep.type))));
            });

            // Add reason to non-acknowledged filter alert flights
            newAlertDepartures.forEach((newAlert) => {
                if (newAlert.type === ALERT_TYPE_FILTER) {
                    newAlert.reason = getFilterReason(newAlert.gufi, ALERT_FILTER_TABLE_ID);
                }
            });

            this.ackedFlights = ackedFlightsToKeep;
            this.props.updateAlertFlights(newAlertDepartures);
        }
        // If there are no alertable flights now but there were previously,
        // make sure the list of alertable flights is clear.  
        else if (this.props.alertFlights && 
                (this.props.alertFlights.length > 0))
        {
            this.props.updateAlertFlights([]);
        }
    };

    /**
     * Processes the alert dialog being acknowledged by updating the list of
     * acknowledged flights and clearing the list of flights to be alerted.
     */
    processAlertAck = (newAckFlights) => {
        this.ackedFlights.push(...newAckFlights);
        localStorage.setItem(this.props.user.username + ":" +
            APP_NAME + ACKED_ALERTS, JSON.stringify(this.ackedFlights));

        this.props.updateAlertFlights(this.props.alertFlights.filter(
            (gufi) => !newAckFlights.includes(gufi)));
    };

    /**
     * Control when this react component should be updated. Some of the
     * properties are just passed through here, so there are only a few
     * that really warrant a rerender.
     */
    shouldComponentUpdate(nextProps, unusedNextState)
    {
        // Don't update the table while settings are loading
        return (this.props.isLoadingSettings !== nextProps.isLoadingSettings) ||
               (!nextProps.isLoadingSettings &&
                   ((this.props.tableGroups !== nextProps.tableGroups) ||
                    (this.props.flightMenus !== nextProps.flightMenus) ||
                    (!this.props.allUpdated && nextProps.allUpdated) ||
                    (nextProps.isIntervalUpdateNeeded)));
    }

    /**
     * Sets up the layout for showing the tables
     *
     * @return {JSX.element} Each of the individual tables.
     */
    renderTables = () =>
    {
        if (!this.props.tableGroups.length)
        {
            return null;
        }

        return (
          <>
            <ShowMeTable />
            { // Render each table. Adding the key to the repeated 
              // padded-layout elements keeps React happy
              this.props.tableGroups.map((tableId) => {
                  return (
                      <padded-layout key={tableId}>
                          <FlightTable tableId={tableId} />
                      </padded-layout>
                  )
              })
            }
            <AlertTable tableId={ALERT_FILTER_TABLE_ID} buttonId={ALERT_FILTER_BUTTON} />
            <AlertTable tableId={CANDIDATE_FILTER_TABLE_ID} buttonId={CANDIDATE_FILTER_BUTTON} />
          </>
        );
    };

    /**
     * Sets up the layout for controlling and displaying the tables and their
     * controls.
     * 
     * @return {JSX.element} The main area where the tables and info pertinent
     *                       to all tables are shown.
     */ 
    render()
    {
        if (this.props.isLoadingSettings)
        {
            return (
                <h1 className="load-settings-label">Loading settings...</h1>
            );
        }
        else
        {
            return (
              <div className="main-frame">
                <FlightAlertModal onAck={this.processAlertAck} />
                <CandidateAlertModal onAck={this.processAlertAck} />
                <MsgModal />
                {this.renderTables()}
                <AddTableButton />
              </div>
            );
        }
    }
}

/**
 * Add the specified global state variables into props for easy access.
 *
 * @param {object} state The current redux state
 *
 * @return {object} The desired redux state properties mapped to props
 */
const mapStateToProps = (state) =>
{
    // check if all the tables have updated themselves to reset scroll
    let numTables = state.tablesReducer.tableGroups.length;
    if ((state.showMeReducer.alertGufis.length !== 0) || 
        (state.showMeReducer.globalSearch !== ""))
    {
        numTables++;
    }
    const allUpdated = (state.tablesReducer.updatedTables.size === numTables);
    const isLoadingSettings = (state.settings.isLoading ||
        state.tablesReducer.isResettingSettings);

    return {
        tableGroups: state.tablesReducer.tableGroups,
        flightMenus: state.flightMenuReducer.flightGufis,
        user: state.authentication.user,
        isLoadingSettings: isLoadingSettings,
        tosApprovers: state.dataReducer.tosStatus.approvalTypes,
        timeMode: state.dataReducer.timeMode,
        dataTimestamp: state.dataReducer.dataTimestamp,
        alertFlights: state.modalReducer.alertFlights,
        isAlertModalOpen: state.modalReducer.alertTypesModalOpen,
        isErrorModalOpen: state.modalReducer.errorModalOpen,
        alertCoordination: (state.modalReducer.alertTypes.coordinationVisual ||
                           state.modalReducer.alertTypes.coordinationAudible),
        alertFiltered : (state.modalReducer.alertTypes.filteredVisual ||
                         state.modalReducer.alertTypes.filteredAudible),
        alertScratchPad : (state.modalReducer.alertTypes.scratchPadVisual ||
                           state.modalReducer.alertTypes.scratchPadAudible),
        alertCandidate: (state.modalReducer.alertTypes.candidateVisual ||
                         state.modalReducer.alertTypes.candidateAudible),
        allUpdated: allUpdated,
        isIntervalUpdateNeeded: state.dataReducer.intervalUpdateNeeded,
    }
};

/**
 * Add the specified action functions into props. These are used as shortcuts
 * to the reducer to update data.
 */
const mapDispatchToProps =
{
    addMessage,
    addTable,
    closeErrorModal,
    openErrorModal,
    startTableUpdates,
    updateAlertFlights,
    updateColumns,
    updateDataTimestamp,
    updateTosStatus,
    updateDepartures,
    updateFlightRoutes,
    updateRefreshInterval,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablesManager);
